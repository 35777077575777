import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getTakeoffData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(`/v2/p/${propertyId}/ee/`);

	return response.data;
};

export const getFlightPlanData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/flight-plans`
	);

	return response.data;
};

export const getAnomalyDataFromSupabase = async (
	propertyId: string,
	flightPlanId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/model-points`,
		{
			params: { flight_plan_id: flightPlanId },
		}
	);

	return response.data;
};

export const getModelLoader = async (
	propertyId: string,
	modelType: string,
	modelName: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/model-loader`,
		{
			params: { model_type: modelType, model_name: modelName },
		}
	);

	return response.data;
};

export const getImagesData = async (
	data: {} = {},
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/images`,
		// points to new v2 endpoint
		{
			params: { data: JSON.stringify(data) },
		}
	);

	return response.data.data;
};

export const getImageMetadata = async (
	propertyId: string,
	thermalImage: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/image-meta-data`,
		{
			params: { thermal_image: thermalImage },
		}
	);

	return response.data;
};

export const getImageThermalData = async (
	propertyId: string,
	thermalImage: string,
	flightPlanId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/image-thermal-data`,
		{
			params: { thermal_image: thermalImage, flight_plan_id: flightPlanId },
		}
	);

	return response.data;
};

export const getOverviewImage = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/overview-image`
	);

	return response.data;
};

export const getPCAReport = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/download-pca-report`
	);

	return response.data;
};

export const getAdminFlightPlanData = async (
	propertyId: string,
	groupId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get('/v2/admin/flight-plans', {
		params: { property_id: propertyId, group_id: groupId },
	});

	return response.data.data;
};

export const getAdminTakeoffData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get('/v2/admin/takeoff', {
		params: { property_id: propertyId },
	});

	return response.data.data;
};

export const getAdminFlightsData = async (
	flightPlanId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get('/v2/admin/flights', {
		params: { flight_plan_id: flightPlanId },
	});

	return response.data.data;
};

export const addTakeoffData = async (
	propertyId: string,
	locationName: string,
	latitude: number,
	longitude: number
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post('/v2/admin/takeoff', {
		property_id: propertyId,
		location_name: locationName,
		latitude: latitude,
		longitude: longitude,
	});

	return response.data.data;
};

export const updateTakeoffData = async (
	takeoffLocationId: string,
	locationName: string,
	latitude: number,
	longitude: number
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.put('/v2/admin/takeoff', {
		takeoff_location_id: takeoffLocationId,
		location_name: locationName,
		latitude: latitude,
		longitude: longitude,
	});

	return response.data.data;
};

export const updateFlightData = async (
	flightId: string,
	date: string,
	wasFlown: number,
	folderLocation: number,
	flightPlanId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.put('/v2/admin/flights', {
		flight_id: flightId,
		date: date,
		was_flown: wasFlown,
		folder_location: folderLocation,
		flight_plan_id: flightPlanId,
	});

	return response.data.data;
};
