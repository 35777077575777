import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import NumberInput from '../../../../inputSheet/numberInput/NumberInput';
// Styles
import '../../DataSharing.scss';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
// Redux
import { updateEnergySetup } from '../../../../../redux/setup/setupSlice';
import { inputConfigurations, UNABLE_TO_REACH_MESSAGE } from '../../const';
import { energyStarApi } from '../../../../../api';

const inputTypes = {
	ACCOUNT: 'account',
	PROPERTY: 'property',
};

function GetEnergyStarInput({
	initialValue,
	inputName,
	buildingName, // Optional: Only required for 'property'
	accountNumber, // Optional: Only required for 'property'
	setShowSnackbar,
	propertyId,
	setCurrentPage,
	id,
}) {
	const dispatch = useDispatch();
	const { energySetupUpdateLoading, energySetupUpdateError } = useSelector(
		(state) => state.setup
	);

	const [inputValue, setInputValue] = useState(initialValue);
	const [loading, setLoading] = useState(false);

	// Retrieve configuration based on inputName
	const config = inputConfigurations[inputName];

	if (!config) {
		console.error(`No configuration found for inputName: ${inputName}`);
		return null;
	}

	const {
		label,
		description,
		inputJsonKey,
		successJsonKey,
		successPageKey,
		failPageKey,
		apiFunction,
		additionalParams: getAdditionalParams,
	} = config;

	// Determine additionalParams
	const additionalParams =
		typeof getAdditionalParams === 'function'
			? getAdditionalParams({ accountNumber })
			: getAdditionalParams;

	const handleValueSubmit = async () => {
		setLoading(true);

		try {
			// Initiate API call and Redux dispatch concurrently
			const apiCall = apiFunction(
				propertyId,
				inputValue || 0,
				...additionalParams
			);
			const dispatchCall = dispatch(
				updateEnergySetup({
					propertyId,
					column_name: inputJsonKey,
					column_value: inputValue,
				})
			);

			const [response] = await Promise.all([apiCall, dispatchCall]);

			if (response.data === true) {
				// Update Redux store to reflect success

				if (inputName === inputTypes.PROPERTY && inputValue) {
					energyStarApi.insertEnergyStarData(propertyId, inputValue);
				}

				await dispatch(
					updateEnergySetup({
						propertyId,
						column_name: successJsonKey,
						column_value: true,
					})
				);
				setCurrentPage(successPageKey);
			} else {
				setCurrentPage(failPageKey);
			}
		} catch (error) {
			setShowSnackbar(UNABLE_TO_REACH_MESSAGE, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="InputContainer">
				<label htmlFor={`${inputName}Number`}>
					<span className="labelTitle">
						{/* Label is a function for property popup */}
						{typeof label === 'function' ? label(buildingName) : label}
					</span>
					{description}
				</label>
				<NumberInput
					propValue={inputValue}
					getChange={setInputValue}
					name={`${inputName}Number`}
					placeholder={`Enter your ${inputName} number`}
					classes="textInput w-100"
				/>
			</div>

			{/* Display error message if saving fails */}
			{energySetupUpdateError && (
				<div className="errorText">Unable to save. Please try again.</div>
			)}

			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					variant="contained"
					onClick={handleValueSubmit}
					disabled={loading || typeof inputValue !== 'number'}
					loading={loading || energySetupUpdateLoading}>
					Next
				</LoadingButton>
			</div>
		</>
	);
}

export function GetAccountNumber({
	accountNumber, // Not needed for account, but kept for consistency
	setShowSnackbar,
	propertyId,
	setCurrentPage,
	id,
}) {
	return (
		<GetEnergyStarInput
			initialValue={accountNumber}
			inputName="account"
			setShowSnackbar={setShowSnackbar}
			propertyId={propertyId}
			setCurrentPage={setCurrentPage}
			id={id}
		/>
	);
}

export function GetPropertyNumber({
	accountNumber, // Required for 'property' additionalParams
	propertyNumber,
	buildingName,
	setShowSnackbar,
	propertyId,
	setCurrentPage,
	id,
}) {
	return (
		<GetEnergyStarInput
			initialValue={propertyNumber}
			inputName="property"
			buildingName={buildingName}
			accountNumber={accountNumber}
			setShowSnackbar={setShowSnackbar}
			propertyId={propertyId}
			setCurrentPage={setCurrentPage}
			id={id}
		/>
	);
}
