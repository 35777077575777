import { LoadingButton } from '@mui/lab';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import {
	basDeviceNetworkConfigurationPageKeys,
	basJsonKeys,
} from '../../const';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import Field from '../../../../inputSheet/Field';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import NumberInput from '../../../../inputSheet/numberInput/NumberInput';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';

const BASDeviceNetworkConfiguration = ({ setShowSnackbar, setPageNumber }) => {
	const dispatch = useDispatch();

	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;

	const { energySetupData } = useSelector((state) => state.setup) || {};

	const { static_network, has_outbound_internet_access } =
		energySetupData || {};

	const [IPAddress, setIPAddress] = useState(static_network?.IPAddress ?? null);
	const [subnetMask, setSubnetMask] = useState(
		static_network?.subnetMask ?? null
	);
	const [gatewayAddress, setGatewayAddress] = useState(
		static_network?.gatewayAddress ?? null
	);
	const [hasOutboundInternetAccess, setHasOutboundInternetAccess] = useState(
		has_outbound_internet_access ?? null
	);

	const [loading, setLoading] = useState(false);

	const handleRadioChange = useCallback((e) => {
		setHasOutboundInternetAccess(e.target.value === 'true'); // Convert string to boolean
	}, []);

	const handleSubmit = async () => {
		setLoading(true);

		const staticNetwork = {
			IPAddress: IPAddress,
			subnetMask: subnetMask,
			gatewayAddress: gatewayAddress,
		};

		const updates = [
			{ column_name: basJsonKeys.STATIC_NETWORK, column_value: staticNetwork },
			{
				column_name: basJsonKeys.HAS_OUTBOUND_INTERNET_ACCESS,
				column_value: hasOutboundInternetAccess,
			},
		];

		try {
			for (const update of updates) {
				if (update.column_value !== null) {
					// Skip null values
					await dispatch(
						updateEnergySetup({
							propertyId,
							column_name: update.column_name,
							column_value: update.column_value,
						})
					);
					dispatch(
						updateEnergyRow({
							[update.column_name]: update.column_value,
						})
					);
				}
			}
			if (hasOutboundInternetAccess) {
				setPageNumber(basDeviceNetworkConfigurationPageKeys.SUCCESS);
			} else {
				setPageNumber(
					basDeviceNetworkConfigurationPageKeys.REQUEST_PORT_ACCESS
				);
			}
		} catch (error) {
			console.error('Error updating energy setup:', error);
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
		} finally {
			setLoading(false);
		}
	};

	const allFilled =
		IPAddress?.trim().length > 0 &&
		IPAddress?.trim().length === 15 &&
		subnetMask?.trim().length > 0 &&
		subnetMask?.trim().length === 15 &&
		gatewayAddress?.trim().length > 0 &&
		gatewayAddress?.trim().length === 15 &&
		typeof hasOutboundInternetAccess === 'boolean';

	return (
		<>
			<p>Please provide the following static network details:</p>

			<IpInputField
				label="IP Address"
				value={IPAddress}
				onChange={setIPAddress}
			/>
			<IpInputField
				label="Subnet mask"
				value={subnetMask}
				onChange={setSubnetMask}
			/>
			<IpInputField
				label="Gateway address"
				value={gatewayAddress}
				onChange={setGatewayAddress}
			/>

			<Field
				inputId={'bacnetDeviceConfigurationMode'}
				label={'BACnet configuration modes'}
				helperText={''}>
				<RadioGroup
					name="radio-buttons-group"
					value={hasOutboundInternetAccess}
					sx={{
						'& .MuiFormControlLabel-root': {
							marginBottom: 0,
						},
					}}>
					{Object.entries({
						"Yes, the building's network has direct web access": true,
						'No, we will need to set that up': false,
					}).map(([label, value]) => (
						<>
							<FormControlLabel
								key={label}
								value={value}
								control={<Radio size="small" onChange={handleRadioChange} />}
								label={label}
							/>
						</>
					))}
				</RadioGroup>
			</Field>
			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					disabled={!allFilled || loading}
					variant="contained"
					onClick={handleSubmit}>
					{!hasOutboundInternetAccess ? 'Next' : 'Submit'}
				</LoadingButton>
			</div>
		</>
	);
};

export default BASDeviceNetworkConfiguration;

const IpInputField = ({ label, value, onChange }) => {
	const handleInputChange = (e) => {
		const input = e.target.value.replace(/[^\d]/g, ''); // Allow only digits
		const groups = input.match(/(\d{1,3})/g) || []; // Group digits into chunks of 1-3
		const formatted = groups.join('.').slice(0, 15); // Join groups with dots, max length 15
		onChange(formatted);
	};

	return (
		<div className="InputContainer">
			<label htmlFor={`deviceId`}>
				<span className="labelTitle">{label}</span>
			</label>
			<input
				type="text"
				value={value}
				onChange={handleInputChange}
				placeholder={`Enter ${label}`}
				maxLength={15} // Prevent more than 15 characters
			/>
		</div>
	);
};
