import axiosInstance from './axiosInstance';
import { axiosFormPost } from './axiosInstance';
import { AxiosResponse } from 'axios';
import { ApiResponse } from './types';

export const uploadFile = async (
	propertyId: string,
	formData: FormData,
	setFileProgress: (fileprogress: number) => void
): Promise<AxiosResponse<any, any>> => {
	return axiosFormPost(
		`/v2/p/${propertyId}/upload-file`,
		formData,
		setFileProgress
	);
};

export const deleteFile = async (
	propertyId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.delete(
		`/v2/p/${propertyId}/delete-file`,
		{ data }
	);

	if (response.status !== 204) {
		throw new Error(`Failed to delete file. Status code: ${response.status}`);
	}

	return response.data;
};

export const adminUploadFile = async (
	formData: FormData,
	setFileProgress: (fileprogress: number) => void
): Promise<AxiosResponse<any, any>> => {
	return axiosFormPost('/v2/admin/upload-file', formData, setFileProgress);
};

export const uploadLogFiles = async (
	formData: FormData,
	setFileProgress: (fileprogress: number) => void
): Promise<AxiosResponse<any, any>> => {
	return axiosFormPost('/v2/admin/upload-file-logs', formData, setFileProgress);
};
