// The default time schedule arrays
export const fullDaySchedule = ['00:00', '23:00'];
export const emptySchedule = [null, null];

// TODO: To be deleted once the 'HVAC and more' section is also refactored
export const initialInputSheet = {
	// Roof Material fields
	R_value_roof: [],
	roof_layersData: [],
	// Wall Material fields
	R_value_wall: [],
	wall_layersData: [],
	// Window Material fields
	U_value_window: '',
	solarHeatGainCoefficient: '',
	emissivity: '',
	overhang: '',
	fins: '',
	windowFinishing: [],
	// HVAC system fields
	hvacSystems: { 0: { motorSystems: { 0: {} } } },
	// New HVAC
	// Heating
	primarySourceOfHeating: null,
	heatingMaintenanceLevel: '',
	heatingPlantAge: '', //number
	boilerPlant: { implemented: null, data: [] },
	electricResistance: { implemented: null, data: [] },
	districtHeatingConnection: { implemented: null, data: [] },
	// Cooling
	primarySourceOfCooling: null,
	coolingMaintenanceLevel: '',
	coolingPLantAge: '', //number
	waterCooledChiller: { implemented: null, data: [] },
	airCooledChiller: { implemented: null, data: [] },
	dx: { implemented: null, data: [] },
	districtCoolingConnection: { implemented: null, data: [] },
	// Heat pumps
	airSourceHeatPump: { implemented: null, data: [] },
	waterSourceHeatPump: { implemented: null, data: [] },
	// Condensing
	coolingTower: { implemented: null, data: [] },
	// Distribution
	ahu: { implemented: null, data: [] },
	unitarySystems: { implemented: null, data: [] },
	hydronic: { implemented: null, data: [] },
	zoneTerminalUnits: { implemented: null, data: [] },
	swud: { implemented: null, data: [] },
	// Ventilation
	typeOfVentilation: null,
	ventilation: {},
	doasMau: { implemented: null, data: [] },
	erv: { implemented: null, data: [] },
	exhaust: { implemented: null, data: [] },
	// Pumps
	motorsServiced: '',
	motorsAvgAge: '',
	pumps: { implemented: null, data: [] },
	// Floor information fields
	numFloors: null,
	heightOfTypicalFloor: null,
	missingFloors: null,
	missingFloorsInfo: null,
	floorInformation: null,
	heightBetweenRoadAndSecondFloor: null,
	additionalFloorInformation: null,
	// Miscellaneous
	isDataSharingComplete: false,
};
