import { useEffect, useState } from 'react';
import {
	CongratsTemplate,
	SimpleActionButtonGroup,
} from '../../CommonTemplates';
import { useSelector } from 'react-redux';
import fontStyles from '../../../../../styles/Fonts.module.scss';
import '../../DataSharing.scss';
import { IssueFoundFullScreenTemplate } from '../../../../uiTemplates/MessageTemplates';
import { useDispatch } from 'react-redux';
import { updateSectionStatusThunk } from '../../../../../redux/inputSheet/inputSheetSlice';
import { guidedSectionMapping } from '../../const';

const guidedSessionOverviewKeys = {
	INTRO: 0,
	HVAC: 1,
	MATERIALS: 2,
	SCHEDULE: 3,
	NEXT_STEPS: 4,
};

const GuidedSessionOverview_2 = ({ onClose }) => {
	const [currentPage, setCurrentPage] = useState(
		guidedSessionOverviewKeys.INTRO
	);
	const guidedSessionPages = {
		[guidedSessionOverviewKeys.INTRO]: <Intro {...{ setCurrentPage }} />,
		[guidedSessionOverviewKeys.HVAC]: <Hvac {...{ setCurrentPage }} />,
		[guidedSessionOverviewKeys.MATERIALS]: (
			<Materials {...{ setCurrentPage }} />
		),
		[guidedSessionOverviewKeys.SCHEDULE]: <Schedule {...{ setCurrentPage }} />,
		[guidedSessionOverviewKeys.NEXT_STEPS]: (
			<NextSteps {...{ onClose, setCurrentPage }} />
		),
	};
	return <div>{guidedSessionPages[currentPage]}</div>;
};

function Intro({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">1</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Please review the information to be collected during the guided
						session.
					</span>
					<br />
					<span>
						Before our second guided session, please take a moment to review the
						questions in the next section. Having this information ready will
						ensure a smoother onboarding process.
					</span>
				</p>
			</div>
			<SimpleActionButtonGroup
				setCurrentPage={setCurrentPage}
				showBack={false}
			/>
		</div>
	);
}

function Hvac({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">2</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						HVAC details including the model, capacity, efficiency, fan
						horsepower, etc. for the applicable equipments:
					</span>
					<br />
					<ul>
						<li>
							Heating equipment (Boiler, Electric resistance, District heating
							connection)
						</li>
						<li>
							Cooling equipment (Water/air cooled chiller, DX - Direct
							expansion, District cooling connection)
						</li>
						<li>Cooling tower</li>
						<li>Air/water source heat pump</li>
						<li>AHU, Unitary systems, Hydronic, Zone terminal units, SWUD</li>
						<li>
							Pumps (details collected include the Gallons Per Minute (GPM),
							Head, Efficiency (EFF), Horsepower (HP), Brake Horsepower (BHP),
							and Variable Frequency Drive (VFD) of pumps)
						</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function Materials({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">3</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Building materials and floors
					</span>
					<br />
					<span className={`${fontStyles.cardInstructions} .subTitles`}>
						Floor information:
					</span>
					<ul>
						<li>Floor count, typical slab and floor height</li>
						<li>Floor names</li>
					</ul>
					<br />
					<span className={`${fontStyles.cardInstructions} .subTitles`}>
						Materials present in the walls and roof:
					</span>
					<ul>
						<li>The R-values of the roof and walls</li>
						<li>The material composition of the roof and walls</li>
						<li>
							The U-value, emissivity, SHGC (Solar Heat Gain Coefficient), etc.
							of the windows
						</li>
					</ul>
					<br />
					<span className={`${fontStyles.cardInstructions} .subTitles`}>
						Atypical set points:
					</span>
					<ul>
						<li>Areas with different setpoint or ventilation requirements</li>
						<li>Areas with different occupancy schedules</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

// Scheduling Guided Session #2
function Schedule({ setCurrentPage }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);

	const [isIframeLoaded, setIsIframeLoaded] = useState(false);
	const [loadTimeoutReached, setLoadTimeoutReached] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isIframeLoaded) {
				setLoadTimeoutReached(true);
			}
		}, 10000); // 10 seconds timeout

		return () => clearTimeout(timer);
	}, [isIframeLoaded]);

	if (!selectedBuilding?.session_2_link) {
		return (
			<IssueFoundFullScreenTemplate message="Your session cannot be scheduled currently. Please contact support." />
		);
	}

	const showInitialLoading = !isIframeLoaded && !loadTimeoutReached;
	const showFallbackMessage = !isIframeLoaded && loadTimeoutReached;

	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">4</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Schedule your guided session
					</span>
					<div style={{ position: 'relative', width: '1200px' }}>
						{(showInitialLoading || showFallbackMessage) && (
							<div
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									backgroundColor: 'rgba(255, 255, 255, 0.9)',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									zIndex: 1,
									textAlign: 'center',
									padding: '20px',
									fontWeight: 500,
								}}>
								{showInitialLoading ? (
									<p>Getting your scheduling link…</p>
								) : (
									<p>
										We’re having trouble loading the scheduling link.
										<br />
										<a
											href={selectedBuilding.session_2_link}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#007bff', textDecoration: 'underline' }}>
											Click here
										</a>{' '}
										to open it in a new tab and schedule your session.
										<br />
										<strong>
											Once you're done, come back to this page to continue.
										</strong>
									</p>
								)}
							</div>
						)}

						<iframe
							title="Calendly"
							src={selectedBuilding.session_2_link}
							width="2000px"
							height="600px"
							className="rounded-lg"
							frameBorder="0"
							onLoad={() => setIsIframeLoaded(true)}
						/>
					</div>
				</p>
			</div>

			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

// Scheduling Guided Session #1
function Schedule1({ setCurrentPage }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);

	const [isIframeLoaded, setIsIframeLoaded] = useState(false);
	const [loadTimeoutReached, setLoadTimeoutReached] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isIframeLoaded) {
				setLoadTimeoutReached(true);
			}
		}, 10000); // 10 seconds timeout

		return () => clearTimeout(timer);
	}, [isIframeLoaded]);

	if (!selectedBuilding?.session_1_link) {
		return (
			<IssueFoundFullScreenTemplate message="Your session cannot be scheduled currently. Please contact support." />
		);
	}

	const showInitialLoading = !isIframeLoaded && !loadTimeoutReached;
	const showFallbackMessage = !isIframeLoaded && loadTimeoutReached;

	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">4</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Schedule your guided session
					</span>
					<div style={{ position: 'relative' }}>
						{(showInitialLoading || showFallbackMessage) && (
							<div
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									backgroundColor: 'rgba(255, 255, 255, 0.9)',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									zIndex: 1,
									textAlign: 'center',
									padding: '20px',
									fontWeight: 500,
								}}>
								{showInitialLoading ? (
									<p>Getting your scheduling link…</p>
								) : (
									<p>
										We’re having trouble loading the scheduling link.
										<br />
										<a
											href={selectedBuilding.session_1_link}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#007bff', textDecoration: 'underline' }}>
											Click here
										</a>{' '}
										to open it in a new tab and schedule your session.
										<br />
										<strong>
											Once you're done, come back to this page to continue.
										</strong>
									</p>
								)}
							</div>
						)}

						<iframe
							title="Calendly"
							src={selectedBuilding.session_1_link}
							width="100%"
							height="600px"
							className="rounded-lg"
							frameBorder="0"
							onLoad={() => setIsIframeLoaded(true)}
						/>
					</div>
				</p>
			</div>

			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

export default Schedule1;
function NextSteps({ onClose, setCurrentPage }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;
	const dispatch = useDispatch();

	const updateSectionStatus = async () => {
		dispatch(
			updateSectionStatusThunk({
				propertyId,
				data: {
					[guidedSectionMapping['Session #2'].key]: {
						isComplete: true,
						isStarted: true,
						section_id: guidedSectionMapping['Session #2'].section_id,
					},
				},
			})
		);
	};

	return (
		<CongratsTemplate
			title="That was it! You are now ready to fill the form."
			message="The 'Guided Session #2: Detailed Building Data' form is available in the next section. Feel free to start entering your information right away or browse the questions to ensure you're fully prepared for the guided session."
			onClick={() => {
				updateSectionStatus();
				onClose();
			}}
			onBackClick={() => setCurrentPage((currentPage) => currentPage - 1)}
			additionalTitleStyles={{ width: '100%' }}
		/>
	);
}

const guidedSessionOverviewKeys_1 = {
	INTRO: 0,
	INITIAL_BUILDING_DATA: 1,
	ENERGY_DATA: 2,
	BUILDING_AUTOMATION_DATA: 3,
	SCHEDULE: 4,
	NEXT_STEPS: 5,
};

const GuidedSessionOverview_1 = ({ onClose }) => {
	const [currentPage, setCurrentPage] = useState(
		guidedSessionOverviewKeys_1.INTRO
	);
	const guidedSessionPages = {
		[guidedSessionOverviewKeys_1.INTRO]: <INTRO_1 {...{ setCurrentPage }} />,
		[guidedSessionOverviewKeys_1.INITIAL_BUILDING_DATA]: (
			<InitialBuildingData {...{ setCurrentPage }} />
		),
		[guidedSessionOverviewKeys_1.ENERGY_DATA]: (
			<EnergyData {...{ setCurrentPage }} />
		),
		[guidedSessionOverviewKeys_1.BUILDING_AUTOMATION_DATA]: (
			<BuildingAutomationData {...{ setCurrentPage }} />
		),
		[guidedSessionOverviewKeys_1.SCHEDULE]: (
			<Schedule1 {...{ setCurrentPage }} />
		),
		[guidedSessionOverviewKeys_1.NEXT_STEPS]: (
			<NEXT_STEPS_1 {...{ onClose, setCurrentPage }} />
		),
	};
	return <div>{guidedSessionPages[currentPage]}</div>;
};

function INTRO_1({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">1</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Welcome to the first guided session overview.
					</span>
					<br />
					<span>
						This session will walk you through the initial building data, energy
						usage details, and automation systems. Please complete these
						sections before our first guided session.
					</span>
				</p>
			</div>
			<SimpleActionButtonGroup
				setCurrentPage={setCurrentPage}
				showBack={false}
			/>
		</div>
	);
}

function InitialBuildingData({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">2</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Initial Building Data
					</span>
					<br />
					<ul>
						<li>
							Relevant building documents (mechanicals, floor plans, etc.)
						</li>
						<li>Building name, address, and year of construction</li>
						<li>Building type (office, residential, mixed-use, etc.)</li>
						<li>Total floor area and number of floors</li>
						<li>Temperature setpoint, lighting information</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function EnergyData({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">3</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Energy Data
					</span>
					<br />
					<ul>
						<li>Electricity and gas utility provider details</li>
						<li>Energy Star details</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function BuildingAutomationData({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">4</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Building Automation System
					</span>
					<br />
					<ul>
						<li>Existing Building Automation System details</li>
						<li>Existing building network details</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function NEXT_STEPS_1({ onClose, setCurrentPage }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;
	const dispatch = useDispatch();

	const updateSectionStatus = async () => {
		dispatch(
			updateSectionStatusThunk({
				propertyId,
				data: {
					[guidedSectionMapping['Session #1'].key]: {
						isComplete: true,
						isStarted: true,
						section_id: guidedSectionMapping['Session #1'].section_id,
					},
				},
			})
		);
	};

	return (
		<CongratsTemplate
			title="Great job! You're ready to proceed."
			message="The next section contains the detailed form where you can enter your building's data. Feel free to start inputting the information."
			onClick={() => {
				updateSectionStatus();
				onClose();
			}}
			onBackClick={() => setCurrentPage((currentPage) => currentPage - 1)}
			additionalTitleStyles={{ width: '100%' }}
		/>
	);
}

const guidedSessionDroneLogistics = {
	SAFETY_FLIGHT: 0,
	INSPECTION_FLIGHT: 1,
	NEXT_STEPS: 2,
};

const GuidedSessionDroneLogistics = ({ onClose }) => {
	const [currentPage, setCurrentPage] = useState(
		guidedSessionOverviewKeys_1.INTRO
	);
	const guidedSessionPages = {
		[guidedSessionDroneLogistics.SAFETY_FLIGHT]: (
			<SafetyFlight {...{ setCurrentPage }} />
		),
		[guidedSessionDroneLogistics.INSPECTION_FLIGHT]: (
			<InspectionFlight {...{ setCurrentPage }} />
		),
		[guidedSessionDroneLogistics.NEXT_STEPS]: (
			<NextStepsDroneScheduling {...{ onClose, setCurrentPage }} />
		),
	};
	return <div>{guidedSessionPages[currentPage]}</div>;
};

function SafetyFlight({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">1</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Safety Flight (In-Person, 2-4 hours)
					</span>
					<br />
					<ul>
						<li>
							<strong>Purpose:</strong> Develop a 3D map of the environment,
							identifying surrounding structures, trees, and potential
							obstacles.
						</li>
						<li>
							<strong>Notification:</strong> You will be notified 30 minutes
							prior to arrival.
						</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup
				setCurrentPage={setCurrentPage}
				showBack={false}
			/>
		</div>
	);
}

function InspectionFlight({ setCurrentPage }) {
	return (
		<div>
			<div className="guidedSessionInstructionsTitle">
				<span className="step-number">2</span>
				<p>
					<span className={`${fontStyles.cardSmallTitle} title`}>
						Inspection Flight (In-Person, 4-16 hours)
					</span>
					<br />
					<ul>
						<li>
							<strong>Purpose:</strong> Conduct a detailed inspection of the
							building’s exterior envelope.{' '}
						</li>
						<li>
							<strong>Building Pressurization Requirement:</strong> To enhance
							the detection of air leakage and assess envelope performance, we
							will request that the building be positively pressurized by
							ensuring that the supply air exceeds the exhaust airflow during
							the inspection.{' '}
						</li>
						<li>
							<strong>Notification:</strong> You will be notified 30 minutes
							prior to arrival.
						</li>
					</ul>
				</p>
			</div>
			<SimpleActionButtonGroup setCurrentPage={setCurrentPage} />
		</div>
	);
}

function NextStepsDroneScheduling({ onClose, setCurrentPage }) {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;
	const dispatch = useDispatch();

	const updateSectionStatus = async () => {
		dispatch(
			updateSectionStatusThunk({
				propertyId,
				data: {
					[guidedSectionMapping.Drone.key]: {
						isComplete: true,
						isStarted: true,
						section_id: guidedSectionMapping.Drone.section_id,
					},
				},
			})
		);
	};

	return (
		<CongratsTemplate
			title="Great job! You're ready to proceed."
			message="The next section contains the detailed form so that we can schedule the flights."
			onClick={() => {
				updateSectionStatus();
				onClose();
			}}
			onBackClick={() => setCurrentPage((currentPage) => currentPage - 1)}
			additionalTitleStyles={{ width: '100%' }}
		/>
	);
}

export {
	GuidedSessionOverview_2,
	GuidedSessionOverview_1,
	GuidedSessionDroneLogistics,
};
