import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getBasDataTree = async (
	property_id: string,
	params: {}
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/bas/get-bas-tree-data`,
		{
			params,
		}
	);
	return response.data;
};

export const getBasData = async (
	property_id: string,
	params: {}
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/bas/get-historical-bas-data`,
		{
			params,
		}
	);
	return response.data;
};
