import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getDocumentInfo = async (): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		'/setup-info/get-document-definition'
	);
	return response.data;
};

export const getDocuments = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/setup/documents`
	);
	return response.data;
};

export const addDocument = async (
	propertyId: string,
	fileName: string,
	awsFileName: string,
	documentTypeId: number
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/setup/documents`,
		{
			fileName,
			awsFileName,
			documentTypeId,
		}
	);
	return response.data;
};

export const deleteDocument = async (
	propertyId: string,
	documentId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.delete(
		`/v2/p/${propertyId}/setup/documents`,
		{
			params: { documentId },
		}
	);
	return response.data;
};

export const getPropertyEnergySetup = async (
	propertyId: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/setup/property-energy`
	);
	return response.data;
};

export const updatePropertyEnergySetup = async (
	propertyId: string,
	column_name: string,
	column_value: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/setup/property-energy`,
		{
			column_name,
			column_value,
		}
	);
	return response.data;
};

export const getSectionQuestions = async (
	sectionName: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get('/setup-info/section-questions', {
		params: { section_name: sectionName },
	});
	return response.data;
};

export const getSectionResponses = async (
	propertyId: string,
	sectionName: Array<string>
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/setup/question-responses`,
		{
			params: { section_name: sectionName },
		}
	);
	return response.data;
};

export const getAdminDocuments = async (
	propertyId: string,
	groupId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get('/v2/admin/documents', {
		params: { property_id: propertyId, group_id: groupId },
	});

	return response.data.data;
};

export const updateSectionResponse = async (
	propertyId: string,
	data: any
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/setup/section-responses`,
		JSON.stringify(data)
	);
	return response.data;
};

export const getSectionStatus = async (
	propertyId: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/setup/section-status`
	);
	return response.data;
};

export const updateSectionStatus = async (
	propertyId: string,
	data: any
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`/v2/p/${propertyId}/setup/section-status`,
		JSON.stringify(data)
	);
	return response.data;
};
