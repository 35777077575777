/**
 * EnrollMFA shows a simple enrollment dialog. When shown on screen it calls
 * the `enroll` API. Each time a user clicks the Enable button it calls the
 * `challenge` and `verify` APIs to check if the code provided by the user is
 * valid.
 * When enrollment is successful, it calls `onEnrolled`. When the user clicks
 * Cancel the `onCancelled` callback is called.
 */

import { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import '../dashboard/dataSharing/DataSharing.scss';
import fontStyles from '../../styles/Fonts.module.scss';
import styles from './auth.module.scss';
import { defaultErrorSnackbar } from '../snackbar/Snackbar';
import { LoadingButton } from '@mui/lab';

const codeLength = 6;

function MFAEnrollment({ handleEnrollmentCompletion, setShowSnackbar }) {
	const [factorId, setFactorId] = useState('');
	const [qr, setQR] = useState(''); // holds the QR code image SVG
	const [isVerifying, setIsVerifying] = useState(false);
	const [verifyCode, setVerifyCode] = useState(''); // contains the code entered by the user
	const [secret, setSecret] = useState('');

	const onSubmitClicked = () => {
		(async () => {
			setIsVerifying(true);
			try {
				// Gets the challenge code for the MFA factor
				const challenge = await supabase.auth.mfa.challenge({ factorId });
				if (challenge.error) {
					throw challenge.error;
				}

				const challengeId = challenge.data.id;

				// Verify the code against the user input
				const verify = await supabase.auth.mfa.verify({
					factorId,
					challengeId,
					code: verifyCode,
				});
				if (verify.error) {
					throw verify.error;
				}
				handleEnrollmentCompletion();
			} catch (error) {
				console.error('Error during MFA process:', error);
				setShowSnackbar({
					...defaultErrorSnackbar,
					msg: 'Unable to verify the code. Please try again.',
				});
			} finally {
				setIsVerifying(false);
			}
		})();
	};

	useEffect(() => {
		(async () => {
			// Unenroll any existing TOTP MFA factor. This is done to ensure
			// that the user is not enrolled in multiple TOTP factors.
			// This happens when the user starts enrolling but leaves it
			// incomplete.
			const { data: factors } = await supabase.auth.mfa.listFactors();
			if (factors && factors.all.length > 0) {
				let factorId = factors.all[0].id;
				await supabase.auth.mfa.unenroll({ factorId });
			}

			// Enroll a new TOTP MFA factor. This will return the secret key and factor ID.
			const { data, error } = await supabase.auth.mfa.enroll({
				factorType: 'totp',
			});
			if (error) {
				throw error;
			}
			setSecret(data.totp.secret);
			setFactorId(data.id);

			// Supabase Auth returns an SVG QR code which you can convert into a data
			// URL that you can place in an <img> tag.
			setQR(data.totp.qr_code);
		})();
	}, []);

	return (
		<div className={styles.mfaEnrollmentContainer}>
			<h2 className={fontStyles.keyTitle}>Multi-factor authentication</h2>
			<ol className={styles.instructionList} style={{ paddingLeft: '20px' }}>
				<li>
					Open your preferred authenticator app, such as
					<strong> Google Authenticator</strong>,
					<strong> Microsoft Authenticator</strong>,<strong> Authy</strong>, or
					<strong> Duo Mobile</strong>.
					<p>
						⚠️{' '}
						<em>
							{' '}
							Please note: some enterprise MFA applications (such as Okta or Duo
							used for internal systems) may not be compatible with this setup.
							For a seamless experience, we recommend using one of the supported
							authenticator apps listed above.
						</em>
					</p>
				</li>
				<li>Scan the QR code shown below with your authenticator app.</li>
				<li>Enter the 6-digit code once it appears.</li>
			</ol>

			<div className={styles.codesToAdd}>
				<img className={styles.qrCode} src={qr} alt="QR code for MFA setup" />

				<p
					className={`${fontStyles.cardInstructions} ${styles.spacedOutTitles}`}>
					<span>Or enter this code manually:</span>
					<span
						className={`${fontStyles.cardDescription} ${fontStyles.goodText}`}>
						{secret}
					</span>
				</p>
			</div>

			<div className={styles.verificationCode}>
				<p className={fontStyles.cardSmallTitle}>
					Enter the verification code here:
				</p>
				<p className={fontStyles.cardDescription}>
					You’ll find this 6-digit code in your authenticator app under{' '}
					<strong>Joulea</strong>.
				</p>

				<VerificationCodeInputAndSubmit
					verifyCode={verifyCode}
					setVerifyCode={setVerifyCode}
					isVerifying={isVerifying}
					onSubmitClicked={onSubmitClicked}
				/>
			</div>
		</div>
	);
}

export default MFAEnrollment;

export function VerificationCodeInputAndSubmit({
	verifyCode,
	setVerifyCode,
	isVerifying,
	onSubmitClicked,
}) {
	return (
		<>
			<input
				type="number"
				value={verifyCode}
				maxLength={codeLength}
				onInput={(e) => (e.target.value = e.target.value.slice(0, codeLength))}
				onChange={(e) => setVerifyCode(e.target.value)}
				className={styles.verificationInput}
				placeholder="Enter the 6-digit code"
			/>
			<LoadingButton
				onClick={onSubmitClicked}
				fullWidth
				loading={isVerifying}
				variant="contained"
				disabled={verifyCode.length !== codeLength}
				className={styles.submitButton}>
				Verify
			</LoadingButton>
		</>
	);
}
