import {
	checkAccountConnection,
	checkPropertySharing,
} from '../../../api/energyStarApi';
import BACnetSetupOnboarding from './popups/basDetails/BACnetSetupOnboarding';
import DeploymentConfigurationOnboarding from './popups/basDetails/DeploymentConfigurationOnboarding';
import DeviceNetworkConfigurationOnboarding from './popups/basDetails/DeviceNetworkConfigurationOnboarding';
import NetworkContextOnboarding from './popups/basDetails/NetworkContextOnboarding';
import BuildingDocumentsOnboarding from './popups/buildingDocuments/BuildingDocumentsOnboarding';
import DroneLogistics from './popups/droneLogistics/DroneLogistics';
import EnergyDirectOnboarding from './popups/energyDirect/EnergyDirectOnboarding';
import EnergyStarOnboarding from './popups/energyStar/EnergyStarOnboarding';
import {
	GuidedSessionDroneLogistics,
	GuidedSessionOverview_1,
	GuidedSessionOverview_2,
} from './popups/guidedSessionOverview/GuidedSessionOverview';

export const PAGE_KEYS = {
	// Drone Flight Logistics
	DRONE_LOGISTICS: 0,
	// Guided Session #1: Initial Building Data
	ENERGY_DIRECT_ACCESS: 1,
	ENERGY_STAR_ACCESS: 2,
	// Guided Session #1: Initial Building Data
	BUILDING_DOCUMENTS: 3,
	BUILDING_DETAILS: 4,
	// Guided Session #2: Detailed Building Data
	GUIDED_SESSION: 5,
	GUIDED_SESSION_OVERVIEW_2: 6, // This section is not tracked or included in the progress calculation
	// Guided Session #1: Building Automation System Data
	DEPLOYMENT_CONFIGURATION: 7,
	DEVICE_NETWORK_CONFIGURATION: 8,
	BACNET_SETUP: 9,
	NETWORK_CONTEXT: 10,
	GUIDED_SESSION_OVERVIEW: 11,
	GUIDED_SESSION_DRONE_LOGISTICS: 12,
};

export const initialOnboardingProgress = Object.values(PAGE_KEYS).reduce(
	(acc, key) => ({ ...acc, [key]: 0 }),
	{}
);

export const guidedSectionMapping = {
	'Session #1': {
		key: 'Guided Session #1 Overview',
		section_id: 25,
	},
	'Session #2': {
		key: 'Guided Session #2 Overview',
		section_id: 26,
	},
	Drone: {
		key: 'Drone Logistics Overview',
		section_id: 27,
	},
};

export const droneLogisticsPageKeys = {
	DATE_SELECTION: 0,
	TAKEOFF_LOCATIONS: 1,
	CHARGING_STATIONS: 2,
	PREFERRED_SET_SUCCESS: 3,
	SAVING: 4,
};
export const popupTitles = {
	[PAGE_KEYS.GUIDED_SESSION_DRONE_LOGISTICS]: 'View Drone Logistics Overview',
	[PAGE_KEYS.DRONE_LOGISTICS]: 'Drone Flight Scheduling',
	[PAGE_KEYS.ENERGY_DIRECT_ACCESS]: 'Energy Direct Access',
	[PAGE_KEYS.ENERGY_STAR_ACCESS]: 'Energy Star Access',
	[PAGE_KEYS.GUIDED_SESSION_OVERVIEW]: 'View Guided Session #1 Overview',
	[PAGE_KEYS.BUILDING_DOCUMENTS]: 'Building Documents',
	[PAGE_KEYS.BUILDING_DETAILS]: 'Building Details',
	[PAGE_KEYS.GUIDED_SESSION_OVERVIEW_2]: 'View Guided Session #2 Overview',
	[PAGE_KEYS.GUIDED_SESSION]: 'HVAC, materials and more',
	[PAGE_KEYS.DEPLOYMENT_CONFIGURATION]: 'Deployment Configuration',
	[PAGE_KEYS.DEVICE_NETWORK_CONFIGURATION]: 'Device Network Configuration',
	[PAGE_KEYS.BACNET_SETUP]: 'BACnet Setup',
	[PAGE_KEYS.NETWORK_CONTEXT]: 'Network Context',
};
export const popupSubtitles = {
	[PAGE_KEYS.GUIDED_SESSION_DRONE_LOGISTICS]:
		'Please review here the information collected in next section.',
	[PAGE_KEYS.DRONE_LOGISTICS]:
		'Confirm your preferences for the upcoming drone flight.',
	[PAGE_KEYS.ENERGY_DIRECT_ACCESS]:
		'Sets up the connection to your Energy Direct account.',
	[PAGE_KEYS.ENERGY_STAR_ACCESS]:
		'Sets up the connection to your Energy Star account.',
	[PAGE_KEYS.BUILDING_DOCUMENTS]: 'Use Ctrl or Shift to upload multiple files.',
	[PAGE_KEYS.GUIDED_SESSION_OVERVIEW]:
		'Please review here the information collected in next section.',
	[PAGE_KEYS.GUIDED_SESSION_OVERVIEW_2]:
		'Please review here the information collected in next section.',
	[PAGE_KEYS.DEPLOYMENT_CONFIGURATION]:
		'The deployment configuration refers to the specific settings and paramets that define how a BAS is implemented and operated.',
	[PAGE_KEYS.DEVICE_NETWORK_CONFIGURATION]:
		'The network configuration refers ot the setup and arrangement of the network infrastructure that enables communication between the various components of the BAS.',
	[PAGE_KEYS.BACNET_SETUP]:
		'BACnet (Building Automation and Control Network) is a widely used protocol standard that defines a set of rules and formats for communication between different devices and systems.',
	[PAGE_KEYS.NETWORK_CONTEXT]:
		'These documents provide us context and help in ensuring data collection accuracy.',
};
export const inputModule = {
	[PAGE_KEYS.GUIDED_SESSION_DRONE_LOGISTICS]: GuidedSessionDroneLogistics,
	[PAGE_KEYS.DRONE_LOGISTICS]: DroneLogistics,
	[PAGE_KEYS.ENERGY_DIRECT_ACCESS]: EnergyDirectOnboarding,
	[PAGE_KEYS.ENERGY_STAR_ACCESS]: EnergyStarOnboarding,
	[PAGE_KEYS.BUILDING_DOCUMENTS]: BuildingDocumentsOnboarding,
	[PAGE_KEYS.GUIDED_SESSION_OVERVIEW]: GuidedSessionOverview_1,
	[PAGE_KEYS.GUIDED_SESSION_OVERVIEW_2]: GuidedSessionOverview_2,
	[PAGE_KEYS.DEPLOYMENT_CONFIGURATION]: DeploymentConfigurationOnboarding,
	[PAGE_KEYS.DEVICE_NETWORK_CONFIGURATION]:
		DeviceNetworkConfigurationOnboarding,
	[PAGE_KEYS.BACNET_SETUP]: BACnetSetupOnboarding,
	[PAGE_KEYS.NETWORK_CONTEXT]: NetworkContextOnboarding,
};
export const initialDroneLogisticsData = {
	selectedDates: [],
	notes: '',
	chargingLocations: '',
	preferredLocations: '',
	takeoffLocations: {},
	additionalNotes: '',
	flightPlanId: '',
};

export const totalDroneLogisticsPages = 3;

export const calculateTotalProgress = (tracking) => {
	if (!tracking || typeof tracking !== 'object') {
		return 0;
	}

	const progressValues = Object.values(tracking);

	const totalProgress = progressValues.reduce(
		(acc, value) => acc + (value || 0),
		0
	);

	// Return the overall progress percentage
	return Math.round(totalProgress / progressValues.length);
};

// BAS Deployment Configuration page keys
export const basDeploymentPageKeys = {
	PRIMARY_BAS_COMMUNICATIONS: 0,
	AVAILABLE_BAS_COMMUNICATIONS: 1,
	SUCCESS: 2,
};

//BAS Device Network Configuration page keys
export const basDeviceNetworkConfigurationPageKeys = {
	DEVICE_NETWORK_CONFIGURATION: 0,
	REQUEST_PORT_ACCESS: 1,
	SUCCESS: 2,
};

// BAS Bacnet details page keys
export const basBacnetSetupPageKeys = {
	BACNET_DETAILS: 0,
	BACNET_CONFIGURATION: 1,
	SUCCESS: 2,
};

// BAS Network Configuration page keys
export const basNetworkContextPageKeys = {
	BAS_DOCUMENTS: 0,
	PREFERRED_EQUIPMENT_TYPES: 1,
	SUCCESS: 2,
};

// ENERGY DIRECT onboarding page keys
export const energyDirectPageKeys = {
	ACCOUNT_EXISTS: 0,
	UPLOAD_UTILITY_BILL: 1,
	UTILITY_BILL_SUCCESS: 2,
	UPLOAD_CONSUMPTION_DATA: 3,
	CONSUMPTION_DATA_SUCCESS: 4,
};

// ENERGY DIRECT onboarding column keys
export const DOES_ED_ACCOUNT_EXIST = 'does_energy_direct_account_exist';

export const UNABLE_TO_REACH_MESSAGE =
	"Sorry, we're having trouble reaching ENERGY STAR. Please try again or contact us for assistance.";

// ENERGY STAR onboarding column keys
export const energyStarJsonKeys = {
	ACCOUNT_NUMBER: 'energy_star_account_number',
	PROPERTY_NUMBER: 'energy_star_property_number',
	IS_CONNECTED: 'is_energy_star_connected',
	IS_SHARED: 'is_energy_star_shared',
	PAGE_NUMBER: 'energy_star_page_number',
};

// ENERGY STAR onboarding page keys
export const energyStarPageKeys = {
	GET_ACCOUNT_NUMBER: 0,
	CONNECT_ACCOUNT: 1,
	ALREADY_CONNECTED: 2,
	GET_PROPERTY_NUMBER: 3,
	SHARE_PROPERTY: 4,
	ALREADY_SHARED: 5,
	SUCCESS: 6,
};

// BAS onboarding page keys
export const basJsonKeys = {
	PRIMARY_BAS_COMMUNICATIONS: 'primary_bas_communications',
	AVAILABLE_BAS_COMMUNICATIONS: 'available_bas_communications',
	STATIC_NETWORK: 'static_network',
	HAS_OUTBOUND_INTERNET_ACCESS: 'has_outbound_internet_access',
	HAS_GIVEN_PORT_ACCESS: 'has_given_port_access',
	IS_ON_SAME_NETWORK: 'is_on_same_network',
	IS_BBMD_PRESENT: 'is_bbmd_present',
	IS_PRIMARY_BACNET: 'is_primary_bacnet',
	HAS_BACNET_CAPABILITY: 'has_bacnet_capability',
	DOES_EXPORT_AS_BACNET: 'does_export_as_bacnet',
	DEVICE_ID: 'device_id',
	BACNET_COMMUNICATION_TYPE: 'bacnet_communication_type',
	BACNET_DEVICE_CONFIGURATION_MODE: 'bacnet_device_configuration_modes',
	PREFERRED_EQUIPMENT_TYPES: 'preferred_equipment_types',
};

// ENERGY STAR account and property input fields
// Configuration mapping based on which input is being collected
export const inputConfigurations = {
	account: {
		label: 'ENERGY STAR Account ID',
		description:
			'The account ID can be found from the Account Settings of ENERGY STAR Portfolio Manager',
		inputJsonKey: energyStarJsonKeys?.ACCOUNT_NUMBER,
		successJsonKey: energyStarJsonKeys?.IS_CONNECTED,
		successPageKey: energyStarPageKeys?.ALREADY_CONNECTED,
		failPageKey: energyStarPageKeys?.CONNECT_ACCOUNT,
		apiFunction: checkAccountConnection,
		additionalParams: [],
	},
	property: {
		label: (buildingName) => `ENERGY STAR Property ID of ${buildingName}`,
		description:
			'The property ID can be found below the property name in the MyPortfolio Dashboard of ENERGY STAR Portfolio Manager',
		inputJsonKey: energyStarJsonKeys?.PROPERTY_NUMBER,
		successJsonKey: energyStarJsonKeys?.IS_SHARED,
		successPageKey: energyStarPageKeys?.ALREADY_SHARED,
		failPageKey: energyStarPageKeys?.SHARE_PROPERTY,
		apiFunction: checkPropertySharing,
		// property requires accountNumber as additionalParams
		additionalParams: (props) => [props.accountNumber || 0],
	},
};

// Get the initial page number for ENERGY STAR onboarding
export const getInitialEnergyStarPageNumber = (energySetupData) => {
	if (energySetupData?.[energyStarJsonKeys.PROPERTY_NUMBER]) {
		return energyStarPageKeys.SHARE_PROPERTY;
	} else if (energySetupData?.[energyStarJsonKeys.IS_CONNECTED]) {
		return energyStarPageKeys.GET_PROPERTY_NUMBER;
	} else if (energySetupData?.[energyStarJsonKeys.ACCOUNT_NUMBER]) {
		return energyStarPageKeys.CONNECT_ACCOUNT;
	} else {
		return energyStarPageKeys.GET_ACCOUNT_NUMBER;
	}
};

// 11 is the utility bill type id
export const UTILITY_BILL_TYPE_ID = 11;
// 12 is the consumption doc type id
export const CONSUMPTION_DOC_TYPE_ID = 12;

// Get the initial page number for ENERGY DIRECT onboarding
export const getInitialEnergyDirectPageNumber = (
	energySetupData,
	documentList
) => {
	if (
		documentList.some((doc) => doc.document_type_id.id === UTILITY_BILL_TYPE_ID)
	) {
		return energyDirectPageKeys.UTILITY_BILL_SUCCESS;
	} else if (
		documentList.some(
			(doc) => doc.document_type_id.id === CONSUMPTION_DOC_TYPE_ID
		)
	) {
		return energyDirectPageKeys.CONSUMPTION_DATA_SUCCESS;
	} else if (energySetupData?.[DOES_ED_ACCOUNT_EXIST] === true) {
		return energyDirectPageKeys.UPLOAD_UTILITY_BILL;
	} else if (energySetupData?.[DOES_ED_ACCOUNT_EXIST] === false) {
		return energyDirectPageKeys.UPLOAD_CONSUMPTION_DATA;
	} else return 0;
};

// Onboarding super sections
// Each super section has a name, tooltip question, tooltip description, and subModuleData
// For example, 'Energy data' has subModuleData of 'Energy direct' and 'ENERGY STAR'
export const onboardingSuperSections = [
	{
		name: 'Drone Logistics Overview',
		// description:
		// 	"This section gathers data and documents related to your building. Please complete the requested information, and Joulea will review the data with you during your first guided session.",
		tooltipQuestion: 'What is this section?',
		tooltipDescription:
			'This section will guide you on what to expect when we come for the drone flights.',
		subModuleData: [PAGE_KEYS.GUIDED_SESSION_DRONE_LOGISTICS],
	},
	{
		name: 'Drone Flight Logistics',
		description:
			'This section collects information required for the first drone flight.',
		tooltipQuestion: 'Why is this data collected?',
		tooltipDescription:
			'It helps us plan your drone flights including flight days, take-off, and charging locations.',
		subModuleData: [PAGE_KEYS.DRONE_LOGISTICS],
	},
	{
		name: 'Guided Session #1 Overview',
		// description:
		// 	"This section gathers data and documents related to your building. Please complete the requested information, and Joulea will review the data with you during your first guided session.",
		tooltipQuestion: 'What is this section?',
		tooltipDescription:
			'This section will guide you on what data to prepare for the coming up session.',
		subModuleData: [PAGE_KEYS.GUIDED_SESSION_OVERVIEW],
	},
	{
		name: 'Guided Session #1: Initial Building Data',
		description:
			'This section gathers data and documents related to your building. Please complete the requested information, and Joulea will review the data with you during your first guided session.',
		tooltipQuestion: 'Why is this data collected?',
		tooltipDescription:
			'This information on the building helps to great an energy digital twin of the building.',
		subModuleData: [PAGE_KEYS.BUILDING_DOCUMENTS, PAGE_KEYS.BUILDING_DETAILS],
	},
	{
		name: 'Guided Session #1: Energy data',
		description:
			'This section collects the historical design and usage data from documents and third-party providers.',
		tooltipQuestion: 'Why is this data collected?',
		tooltipDescription: `This data is used to create an energy digital twin of your building. The energy data of at least the past 1 year is required for creation of the energy digital twin.`,
		subModuleData: [
			PAGE_KEYS.ENERGY_DIRECT_ACCESS,
			PAGE_KEYS.ENERGY_STAR_ACCESS,
		],
	},
	{
		name: 'Guided Session #1: Building Automation System Data',
		description:
			"This section may require the guidance of your organization's IT, Network, and BAS personnel. Joulea will work with you to ensure this section is filled in the first guided session.",
		tooltipQuestion: 'Why is this data collected?',
		tooltipDescription:
			'This data is used to setup the connection to your BAS and to understand the equipment and systems in your building.',
		subModuleData: [
			PAGE_KEYS.DEPLOYMENT_CONFIGURATION,
			PAGE_KEYS.DEVICE_NETWORK_CONFIGURATION,
			PAGE_KEYS.BACNET_SETUP,
			PAGE_KEYS.NETWORK_CONTEXT,
		],
	},
	{
		name: 'Guided Session #2 Overview',
		// description:
		// 	'This data will be collected in guided sessions with Joulea’s engineers. Please review the forms before the session.',
		tooltipQuestion: 'What is this section?',
		tooltipDescription:
			'This section will guide you on what data to prepare for the coming up session.',
		subModuleData: [PAGE_KEYS.GUIDED_SESSION_OVERVIEW_2],
	},
	{
		name: 'Guided Session #2: Detailed Building Data',
		description:
			'This data will be collected in guided sessions with Joulea’s engineers. Please review the forms before the session.',
		tooltipQuestion: 'What is this section?',
		tooltipDescription:
			'This information on the building helps to create an energy digital twin of the building. This data will recorded by Joulea’s building engineers with your consultation.',
		subModuleData: [PAGE_KEYS.GUIDED_SESSION],
	},
];
