import { useSelector } from 'react-redux';
import FileUpload from './FileUpload';
import { LoadingButton } from '@mui/lab';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import { useEffect } from 'react';
import { calculateDocumentProgress } from '../../calculateSectionProgress';
import {
	ErrorTemplate,
	LoadingTemplate,
} from '../../../../uiTemplates/MessageTemplates';

function BuildingDocumentsOnboarding({ onClose, setShowSnackbar }) {
	const propertyId = useSelector((state) => state.selectedBuilding).prop_id;
	const {
		documentDefinition,
		documentDefinitionLoading,
		documentDefinitionError,
		documentList,
		documentListError,
	} = useSelector((state) => state.setup);

	const removeBuildingDocuments = [10, 11, 12, 13, 14, 15, 16, 17];

	const filteredDocumentDefinition = documentDefinition.filter(
		(item) => !removeBuildingDocuments.includes(item.id)
	);

	// Check if all required documents are uploaded
	const isAllRequiredDocsUploaded =
		calculateDocumentProgress(documentList, filteredDocumentDefinition) === 100;

	// Show error message if there is an error fetching the documents
	useEffect(() => {
		if (documentListError) {
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
		}
	}, [documentListError, setShowSnackbar]);

	// Show loading template if the document definition is still loading
	if (documentDefinitionLoading) {
		return <LoadingTemplate />;
	}

	// Show error template if there is an error fetching the document definition
	if (documentDefinitionError) {
		return <ErrorTemplate />;
	}

	return (
		<>
			<div className="FileUploadContainer">
				{
					// Loop through all the documents and display the FileUpload component for each
					(filteredDocumentDefinition || []).map((singleInfo) => (
						<FileUpload
							key={singleInfo.id}
							documentTypeIndex={singleInfo.id}
							documentName={singleInfo.name}
							description={singleInfo.description}
							documents={documentList.filter(
								(doc) => doc?.document_type_id?.id === singleInfo.id
							)}
							inputJsonKey={singleInfo.jsonKey}
							propertyId={propertyId}
							setShowSnackbar={setShowSnackbar}
							allowedFileTypes={singleInfo.allowedFileTypes}
							isRequired={singleInfo.isRequired}
						/>
					))
				}
			</div>
			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					disabled={!isAllRequiredDocsUploaded}
					variant="contained"
					onClick={onClose}>
					Submit
				</LoadingButton>
			</div>
		</>
	);
}

export default BuildingDocumentsOnboarding;
