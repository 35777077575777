import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getSimilarDates = async (
	property_id: string,
	params: {}
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/opx/get-similar-dates`,
		{
			params,
		}
	);
	return response.data;
};

export const getMeanHalfHourlyDemand = async (
	property_id: string,
	params: {}
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/opx/get-half-hourly-mean-data`,
		{
			params,
		}
	);
	return response.data;
};

export const getHistoricalConsumptionData = async (
	property_id: string,
	params: {}
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/opx/get-historical-consumption-data`,
		{ params }
	);
	return response.data;
};

export const getAggregatedWeather = async (
	property_id: string,
	date: string,
	weather_location_id: string,
	aggregationType: string
): Promise<ApiResponse<any[]>> => {
	const response = await axiosInstance.get(
		`/v2/p/${property_id}/opx/get-aggregated-weather-data`,
		{
			params: {
				end_date: date,
				weather_location_id: weather_location_id,
				aggregation_type: aggregationType,
			},
		}
	);
	return response.data;
};
