import dayjs from 'dayjs';
import fontStyles from '../../../../../styles/Fonts.module.scss';
import Field from '../../../../inputSheet/Field';
const dateDisplayFormat = 'DD MMM, ddd';

export function SelectionInstructions({
	selectedDates,
	notes,
	handleNotesChange,
}) {
	return (
		<div>
			<p>Please select your preferred 4-week window for the drone flights. </p>
			<p className={`${fontStyles.cardSmallTitle} newSectionTitle`}>
				Selected days:
			</p>
			{selectedDates && selectedDates.length > 0 ? (
				<p>
					{dayjs(selectedDates[0]).format(dateDisplayFormat)} until{' '}
					{dayjs(selectedDates.at(-1)).format(dateDisplayFormat)}
				</p>
			) : (
				<p className={fontStyles.cardInstructions}>
					No days selected. Please select on the calendar.
				</p>
			)}
			<p className={`${fontStyles.cardSmallTitle} newSectionTitle`}>Notes:</p>
			<textarea
				value={notes ? notes : ''}
				onChange={(e) => handleNotesChange(e.target.value)}
				placeholder="Notes..."
				style={{
					width: '100%',
					minHeight: '100px',
					border: '1px solid #ccc',
					borderRadius: '4px',
					padding: '10px',
					fontSize: '14px',
				}}
			/>
		</div>
	);
}
