import { ActionButtonGroup } from '../../CommonTemplates';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import { basBacnetSetupPageKeys, basJsonKeys } from '../../const';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import Field from '../../../../inputSheet/Field';
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import toggleButtonStyles from '../../../../inputSheet/ToggleButton.module.scss';
import NumberInput from '../../../../inputSheet/numberInput/NumberInput';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';

const BacnetConfiguration = ({ setShowSnackbar, setPageNumber }) => {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const dispatch = useDispatch();

	const propertyId = selectedBuilding?.prop_id; // Optional chaining for safety

	const { energySetupData } = useSelector((state) => state.setup) || {};

	const {
		device_id,
		bacnet_communication_type,
		bacnet_device_configuration_mode,
	} = energySetupData || {};

	const [deviceId, setDeviceId] = useState(device_id ?? '');
	const [bacnetCommunicationType, setBacnetCommunicationType] = useState(
		bacnet_communication_type ?? null
	);
	const [bacnetDeviceConfigurationMode, setBacnetDeviceConfigurationMode] =
		useState(bacnet_device_configuration_mode ?? '');

	const [loading, setLoading] = useState(false);

	const allFilled =
		deviceId === '' ||
		bacnetCommunicationType === null ||
		bacnetDeviceConfigurationMode === '';

	const handleToggleChange = useCallback((e, newValue) => {
		setBacnetCommunicationType(newValue);
	}, []);

	const handleRadioChange = useCallback((e) => {
		setBacnetDeviceConfigurationMode(e.target.value);
	}, []);

	const handleSubmit = async () => {
		setLoading(true);

		const updates = [
			{ column_name: basJsonKeys.DEVICE_ID, column_value: deviceId },
			{
				column_name: basJsonKeys.BACNET_COMMUNICATION_TYPE,
				column_value: bacnetCommunicationType,
			},
			{
				column_name: basJsonKeys.BACNET_DEVICE_CONFIGURATION_MODE,
				column_value: bacnetDeviceConfigurationMode,
			},
		];

		try {
			for (const update of updates) {
				if (update.column_value !== null) {
					// Skip null values
					await dispatch(
						updateEnergySetup({
							propertyId,
							column_name: update.column_name,
							column_value: update.column_value,
						})
					);
					dispatch(
						updateEnergyRow({
							[update.column_name]: update.column_value,
						})
					);
				}
			}
			setPageNumber(basBacnetSetupPageKeys.SUCCESS);
		} catch (error) {
			console.error('Error updating energy setup:', error);
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="InputContainer">
				<label htmlFor={`deviceId`}>
					<span className="labelTitle">Device ID</span>A unique BACnet device
					number in the network
				</label>
				<NumberInput
					propValue={deviceId}
					getChange={setDeviceId}
					name={`deviceId`}
					placeholder={`Enter device number`}
					classes="textInput w-100"
				/>
			</div>
			<Field inputId={''} label={'BACnet communication type'} helperText={''}>
				<ToggleButtonGroup
					className={toggleButtonStyles.popupButtonGroupContainer}
					value={bacnetCommunicationType}
					exclusive
					onChange={handleToggleChange}>
					{Object.entries({
						Ethernet: 'Ethernet',
						'TCP/IP': 'TCP/IP',
					}).map(([label, value]) => (
						<ToggleButton
							key={label}
							value={value}
							className={toggleButtonStyles.popupButton}>
							{label}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Field>
			<Field
				inputId={'bacnetDeviceConfigurationMode'}
				label={'BACnet configuration modes'}
				helperText={''}>
				<RadioGroup
					name="radio-buttons-group"
					value={bacnetDeviceConfigurationMode}
					sx={{
						'& .MuiFormControlLabel-root': {
							marginBottom: 0,
						},
					}}>
					{Object.entries({
						BBMD: 'When there is no BBMD devices and need to communicate with other BACnet devices from another network.',
						'Foreign Device':
							'When BBMD configuration is not applicable and need to communicate with devices from another network.',
					}).map(([value, label]) => (
						<>
							<FormControlLabel
								key={label}
								value={value}
								control={<Radio size="small" onChange={handleRadioChange} />}
								label={value}
							/>
							<span>{label}</span>
						</>
					))}
				</RadioGroup>
			</Field>
			<div className={buttonStyles.dataSharingButtons}>
				<ActionButtonGroup
					pageToNavigateOnBack={basBacnetSetupPageKeys.BACNET_DETAILS}
					setPageNumber={setPageNumber}
					disableCTA={allFilled || loading}
					ctaClickHandler={handleSubmit}
				/>
			</div>
		</>
	);
};

export default BacnetConfiguration;
