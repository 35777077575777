import { useSelector } from 'react-redux';
import FileUpload from '../buildingDocuments/FileUpload';
import { useEffect } from 'react';
import {
	ErrorTemplate,
	LoadingTemplate,
} from '../../../../uiTemplates/MessageTemplates';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import { calculateDocumentProgress } from '../../calculateSectionProgress';
import { LoadingButton } from '@mui/lab';
import { basNetworkContextPageKeys } from '../../const';

const BasDocuments = ({ setShowSnackbar, setPageNumber }) => {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;
	const {
		documentDefinition,
		documentDefinitionLoading,
		documentDefinitionError,
		documentList,
		documentListError,
	} = useSelector((state) => state.setup);
	const removeBuildingDocuments = [14, 15, 16, 17];

	const filteredDocumentDefinition = documentDefinition.filter((item) =>
		removeBuildingDocuments.includes(item.id)
	);

	// Check if all required documents are uploaded
	const isAllRequiredDocsUploaded =
		calculateDocumentProgress(documentList, filteredDocumentDefinition) === 100;

	// Show error message if there is an error fetching the documents
	useEffect(() => {
		if (documentListError) {
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
		}
	}, [documentListError, setShowSnackbar]);

	if (documentDefinitionLoading) {
		return <LoadingTemplate />;
	}

	// Show error template if there is an error fetching the document definition
	if (documentDefinitionError) {
		return <ErrorTemplate />;
	}

	return (
		<>
			<div className="FileUploadContainer">
				{
					// Loop through all the documents and display the FileUpload component for each
					(filteredDocumentDefinition || []).map((singleInfo) => (
						<FileUpload
							key={singleInfo.id}
							documentTypeIndex={singleInfo.id}
							documentName={singleInfo.name}
							description={singleInfo.description}
							documents={documentList.filter(
								(doc) => doc?.document_type_id?.id === singleInfo.id
							)}
							inputJsonKey={singleInfo.jsonKey}
							propertyId={propertyId}
							setShowSnackbar={setShowSnackbar}
							allowedFileTypes={singleInfo.allowedFileTypes}
							isRequired={singleInfo.isRequired}
						/>
					))
				}
			</div>
			<div className={buttonStyles.dataSharingButtons}>
				<LoadingButton
					disabled={!isAllRequiredDocsUploaded}
					variant="contained"
					onClick={() =>
						setPageNumber(basNetworkContextPageKeys.PREFERRED_EQUIPMENT_TYPES)
					}>
					Next
				</LoadingButton>
			</div>
		</>
	);
};

export default BasDocuments;
