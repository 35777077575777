import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { initialInputSheet } from '../../assets/initial_input_sheet';
import { store } from '../../Store';
import { userRoleMap } from '../../utils/maps';
import { initialOnboardingProgress } from '../../components/dashboard/dataSharing/const';
import { getIfUserHasReadOnlyAccess } from '../../api/propertyApi';

export type CalibrationType = {
	state: 'error' | 'running' | 'success';
	last_complete: string;
	time_started: string | null;
} | null;

export type TopsisType = {
	state: 'error' | 'running' | 'success';
	last_complete: string;
	time_started: string | null;
} | null;

interface SelectedBuildingState {
	prop_name: string;
	prop_id: string;
	// TODO: add typing for all input sheet values
	input_sheet: Record<string, any>;
	onboarding_progress: Record<string, number>;
	is_onboarding_complete: boolean | null;
	group_name: string;
	region: string;
	city: string;
	gross_floor_area: number | null;
	is_read_only: boolean;
	can_submit: boolean;
	loading: boolean;
	created_at: string;
}

const initialState: SelectedBuildingState = {
	prop_name: '',
	prop_id: '',
	input_sheet: initialInputSheet,
	onboarding_progress: initialOnboardingProgress,
	is_onboarding_complete: null,
	group_name: '',
	region: '',
	city: '',
	gross_floor_area: null,
	is_read_only: true,
	can_submit: false,
	loading: false,
	created_at: '',
};

function mergeSheets(savedSheet: any, updatedSheet: any) {
	// Create a new merged sheet with the saved sheet as the base
	const mergedSheet = { ...savedSheet };

	// Update the merged sheet with keys from the updated sheet
	for (const key in updatedSheet) {
		if (updatedSheet.hasOwnProperty(key) && !savedSheet.hasOwnProperty(key)) {
			mergedSheet[key] = updatedSheet[key];
		}
	}

	return mergedSheet;
}

export const addSelectedBuildingAsync =
	(buildingData: SelectedBuildingState) => async (dispatch: Dispatch) => {
		const { userDetails } = store?.getState()?.user;

		// Merge the saved input sheet with the initial input sheet (to add any new keys)
		const mergedSheet = mergeSheets(
			buildingData.input_sheet,
			initialInputSheet
		);

		// Save if the user can only read the data
		let isReadOnly = null;
		try {
			if (userDetails.role === userRoleMap.Owner) {
				isReadOnly = false;
			} else {
				const response = await getIfUserHasReadOnlyAccess(buildingData.prop_id);
				isReadOnly = response.data;
			}
		} catch (error) {
			// Handle any errors here
			console.error('Error fetching data:', error);
		}

		// Update the building object with the merged input sheet and read-only status
		const building = {
			...buildingData,
			input_sheet: mergedSheet,
			is_read_only: isReadOnly,
			loading: false,
		};

		dispatch(addSelectedBuilding(building));
	};

const selectedBuildingSlice = createSlice({
	name: 'selectedBuilding',
	initialState,
	reducers: {
		updateOnboardingProgress: (state, { payload: progressObject }) => {
			const newState = { ...state, onboarding_progress: progressObject };
			return newState;
		},
		updateSelectedBuilding: (state, { payload }) => {
			return { ...state, ...payload };
		},
		addSelectedBuilding: (_, { payload }) => {
			return payload;
		},
		// TODO: The below 2 reducers can be removed once 'HVAC and more' is refactored
		// Update the input sheet object with the new object received
		updateInputSheetWithObj: (state, { payload: newValues }) => {
			if (typeof newValues === 'object' && newValues !== null) {
				state.input_sheet = { ...state.input_sheet, ...newValues };
			}
			return state;
		},
		updateInputSheetVal: (state, { payload }) => {
			/// An array of strings (the list of keys at every level) is sent as the name
			// For example, if [a, b, c] is the name sent, then input_sheet[a][b][c] will be updated
			// val {any} of the corresponding value
			const { name, val } = payload;
			// If the key is a string, directly replace the value
			if (typeof name === 'string') {
				state.input_sheet[name] = val;
			}
			// If an array is sent, replace the value at the end of tree
			else if (Array.isArray(name)) {
				if (name.length) {
					let currentObj = state.input_sheet;
					// If any of the previous keys do not exist, create a key and assign it an empty object
					name.slice(0, -1).forEach((key) => {
						currentObj[key] = currentObj[key] || {};
						currentObj = currentObj[key];
					});
					// Assign the value for the final key
					const lastKey = name[name.length - 1];
					currentObj[lastKey] = val;
				}
			}
			return state;
		},
		resetSelectedBuilding: (_) => {
			return initialState;
		},
	},
});

export const {
	updateOnboardingProgress,
	updateSelectedBuilding,
	addSelectedBuilding,
	updateInputSheetWithObj,
	updateInputSheetVal,
	resetSelectedBuilding,
} = selectedBuildingSlice.actions;

export default selectedBuildingSlice.reducer;
