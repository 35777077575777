import { useSelector } from 'react-redux';
import { useState, useCallback } from 'react';
import { basDeploymentPageKeys, basJsonKeys } from '../../const';
import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import Field from '../../../../inputSheet/Field';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';
import { ActionButtonGroup } from '../../CommonTemplates';

const AvailableBasCommunications = ({ setShowSnackbar, setPageNumber }) => {
	const dispatch = useDispatch();

	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding?.prop_id; // Optional chaining for safety

	const { energySetupData } = useSelector((state) => state.setup) || {};
	const { available_bas_communications } = energySetupData || {};

	const [selected, setSelected] = useState(
		available_bas_communications?.selected ?? []
	);
	const [others, setOthers] = useState(
		available_bas_communications?.others ?? ''
	);
	const [loading, setLoading] = useState(false);

	const checkboxOptions = [
		'BACnet/IP',
		'BACnet/Ethernet',
		'BACnet/MSTP',
		'Modbus/IP',
		'Niagara Framework',
		'LON',
		'Others',
	];

	const handleCheckboxChange = useCallback((e) => {
		const { name } = e.target;
		setSelected(
			(prevSelected) =>
				prevSelected.includes(name)
					? prevSelected.filter((item) => item !== name) // Remove item
					: [...prevSelected, name] // Add item
		);
	}, []);

	const handleTextBoxChange = useCallback((e) => {
		const { value } = e.target;
		setOthers(value);
	}, []);

	const handleSubmit = async () => {
		// Save the selected and others to the store
		setLoading(true);
		const finalValue = {
			selected: selected,
			others: others,
		};
		try {
			await dispatch(
				updateEnergySetup({
					propertyId,
					column_name: basJsonKeys.AVAILABLE_BAS_COMMUNICATIONS,
					column_value: finalValue,
				})
			);
			dispatch(
				updateEnergyRow({
					[basJsonKeys.AVAILABLE_BAS_COMMUNICATIONS]: finalValue,
				})
			);
			setPageNumber(basDeploymentPageKeys.SUCCESS);
		} catch (error) {
			console.error('Error updating energy setup:', error);
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
		} finally {
			setLoading(false);
		}
	};

	const allFilled =
		selected.length === 0 || (selected.includes('Others') && others === '');

	return (
		<>
			<Field
				inputId={'Available BAS Communications'}
				label={'Available BAS Communications (Field Level)'}
				helperText={'Select all that apply'}>
				<FormGroup>
					{checkboxOptions.map((option, index) => (
						<FormControlLabel
							key={index}
							control={
								<Checkbox
									checked={selected?.includes(option)}
									onChange={handleCheckboxChange}
									name={option}
								/>
							}
							label={option}
						/>
					))}
				</FormGroup>
			</Field>

			{selected.includes('Others') && (
				<Field
					inputId={'Others'}
					label={'Please specify the other communication'}
					helperText={'Write separated by a comma'}>
					<textarea
						value={others}
						onChange={handleTextBoxChange}
						name={'others'}
						id={'others'}
						placeholder={'Enter here'}
						className="textInput w-100"
						style={{
							padding: '10px 16px',
							height: '100px',
							fontFamily: 'Inter, sans-serif',
						}}
					/>
				</Field>
			)}
			<div className={buttonStyles.dataSharingButtons}>
				<ActionButtonGroup
					pageToNavigateOnBack={
						basDeploymentPageKeys.PRIMARY_BAS_COMMUNICATIONS
					}
					setPageNumber={setPageNumber}
					disableCTA={allFilled || loading}
					ctaClickHandler={handleSubmit}
				/>
			</div>
		</>
	);
};

export default AvailableBasCommunications;
