import axiosInstance from './axiosInstance'; // Import the Axios instance for API calls
import { ApiResponse } from './types'; // Ensure this exists in your project
// Type definition for filtering drones (if applicable)
type DroneFilter = {
	drone_name?: string; // Optional: filter by drone name
};
// Type definition for creating or updating a drone
type DroneData = {
	drone_faa_id: string;
	drone_name: string;
	drone_product_id: string;
};
// Fetch all drones (supports optional filtering)
export const getDrones = async (
	filter: DroneFilter
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get('/drones', {
			params: filter || {}, // Pass filters if provided
		});
		return response.data;
	} catch (error) {
		throw error; // Propagate errors to the caller
	}
};
// Add a new drone
export const addDrone = async (
	droneData: DroneData
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post('/drone', droneData);
		return response.data;
	} catch (error) {
		throw error;
	}
};
