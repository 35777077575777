export const SELECTION_TYPES = {
	SYSTEM: 'system',
	DEVICE: 'devices',
	SENSOR: 'sensors',
};

export const emptySelection = {
	[SELECTION_TYPES.SYSTEM]: null,
	[SELECTION_TYPES.DEVICE]: [],
	[SELECTION_TYPES.SENSOR]: [],
};

export const allChartsDefault = [
	{
		chartName: 'Chart 1',
		// Currently, only one unit can be selected (single Y axis)
		unit1: null,
		// TODO: Later, allow up to 2 units to be selected (double Y axis)
		unit2: null,
		selectedSensors: [emptySelection],
	},
];
