import buttonStyles from '../../../../uiTemplates/Button.module.scss';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { basJsonKeys, basNetworkContextPageKeys } from '../../const';
import { ActionButtonGroup } from '../../CommonTemplates';
import {
	updateEnergyRow,
	updateEnergySetup,
} from '../../../../../redux/setup/setupSlice';

const PreferredEquipmentTypes = ({ setShowSnackbar, setPageNumber }) => {
	const selectedBuilding = useSelector((state) => state.selectedBuilding);
	const propertyId = selectedBuilding.prop_id;

	const { energySetupData } = useSelector((state) => state.setup);
	const { preferred_equipment_types } = energySetupData;
	const [text, setText] = useState(preferred_equipment_types ?? '');
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const handleValueChange = (e) => {
		setText(e.target.value);
		dispatch(
			updateEnergyRow({
				[basJsonKeys.PREFERRED_EQUIPMENT_TYPES]: e.target.value,
			})
		);
	};

	const handleSubmit = async () => {
		setLoading(true);

		try {
			await dispatch(
				updateEnergySetup({
					propertyId,
					column_name: basJsonKeys.PREFERRED_EQUIPMENT_TYPES,
					column_value: text,
				})
			);

			// Only proceed after the dispatch is complete
			setLoading(false);
			setPageNumber(2);
		} catch (error) {
			console.error('Error updating energy setup:', error);
			setShowSnackbar(
				'Error saving your information. Please try again later.',
				'error'
			);
			setLoading(false); // Ensure loading state is reset even on failure
		}
	};

	return (
		<>
			<p>
				What is the list of preferred equipment types that you expect to collect
				data from the building?
			</p>
			<textarea
				value={text}
				onChange={handleValueChange}
				id="preferredEquipmentTypes"
				placeholder="List the equipment types here"
				className="textInput w-100"
				style={{
					padding: '10px 16px',
					height: '100px',
					fontFamily: 'Inter, sans-serif',
				}}
			/>
			<div className={buttonStyles.dataSharingButtons}>
				<ActionButtonGroup
					pageToNavigateOnBack={basNetworkContextPageKeys.BAS_DOCUMENTS}
					setPageNumber={setPageNumber}
					disableCTA={!preferred_equipment_types || loading}
					ctaClickHandler={handleSubmit}
				/>
			</div>
		</>
	);
};

export default PreferredEquipmentTypes;
