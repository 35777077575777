import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as opxApi from '../../api/opxApi';

export const getAggregatedWeatherData = createAsyncThunk(
	'opx/getAggregatedWeather',
	async (payload, thunkAPI) => {
		try {
			const { property_id, date, weather_location_id, aggregationType } =
				payload;
			const { data } = await opxApi.getAggregatedWeather(
				property_id,
				date,
				weather_location_id,
				aggregationType
			);
			if (data) {
				return { data, aggregationType };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const initialOpxState = {
	aggregatedWeatherData: {
		daily: {
			trailing_year: [],
			previous_trailing_year: [],
		},
		monthly: {
			trailing_year: [],
			previous_trailing_year: [],
		},
	},
	isAggregatedWeatherDataLoading: false,
	aggregatedWeatherDataError: '',
};

const opxSlice = createSlice({
	name: 'opx',
	initialState: initialOpxState,
	extraReducers: (builder) => {
		builder.addCase(getAggregatedWeatherData.pending, (state, { meta }) => {
			const weather_location_id = meta.arg?.weather_location_id;
			if (!weather_location_id) {
				return;
			}

			state[weather_location_id] = {
				...(weather_location_id in state
					? state[weather_location_id]
					: initialOpxState),
				isAggregatedWeatherDataLoading: true,
			};
		});

		builder.addCase(
			getAggregatedWeatherData.fulfilled,
			(state, { meta, payload }) => {
				const weather_location_id = meta.arg?.weather_location_id;
				const aggregationType = payload.aggregationType; // either 'daily' or 'monthly'

				if (!weather_location_id || !aggregationType) {
					return;
				}

				state[weather_location_id] = {
					...(weather_location_id in state
						? state[weather_location_id]
						: initialOpxState),
					aggregatedWeatherData: {
						...state[weather_location_id].aggregatedWeatherData,
						[aggregationType]: {
							trailing_year: payload.data.trailing_year || [],
							previous_trailing_year: payload.data.previous_trailing_year || [],
						},
					},
					isAggregatedWeatherDataLoading: false,
					aggregatedWeatherDataError: '',
				};
			}
		);

		builder.addCase(
			getAggregatedWeatherData.rejected,
			(state, { meta, error }) => {
				const weather_location_id = meta.arg?.weather_location_id;
				if (!weather_location_id) {
					return;
				}

				state[weather_location_id] = {
					...(weather_location_id in state
						? state[weather_location_id]
						: initialOpxState),
					isAggregatedWeatherDataLoading: false,
					aggregatedWeatherDataError: error.message,
				};
			}
		);
	},
});

export default opxSlice.reducer;
