import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as setupApi from '../../api/setupApi';

interface PropertyIdPayload {
	propertyId: string;
}

interface IdPayload {
	propertyId: string;
	column_name: string;
	column_value: any;
}

interface DocumentListResponse {
	documentList: any;
}

interface EnergySetupResponse {
	energySetup: any;
}

interface UpdateEnergySetupResponse {
	id: number;
}

interface SetupState {
	documentDefinition: any[];
	documentDefinitionLoading: boolean;
	documentDefinitionError: string | null;
	documentList: any[];
	documentListLoading: boolean;
	documentListError: string | null;
	energySetupData: any;
	energySetupDataLoading: boolean;
	energySetupDataError: string | null;
	energySetupUpdateLoading: boolean;
	energySetupUpdateError: string | null;
}

const initialState: SetupState = {
	documentDefinition: [],
	documentDefinitionLoading: false,
	documentDefinitionError: null,
	documentList: [],
	documentListLoading: false,
	documentListError: null,
	energySetupData: {},
	energySetupDataLoading: false,
	energySetupDataError: null,
	energySetupUpdateLoading: false,
	energySetupUpdateError: null,
};

export const getDocumentDefinition = createAsyncThunk(
	'setup/getDocumentDefinition',
	async (_, thunkAPI) => {
		try {
			const { data: documentDefinition } = await setupApi.getDocumentInfo();
			if (documentDefinition) {
				return { documentDefinition };
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const getDocumentList = createAsyncThunk<
	DocumentListResponse,
	PropertyIdPayload
>('setup/getDocumentList', async (payload, thunkAPI) => {
	try {
		const { data: documentList } = await setupApi.getDocuments(
			payload.propertyId
		);
		if (documentList) {
			return { documentList };
		} else {
			return thunkAPI.rejectWithValue('No data found');
		}
	} catch (error) {
		return thunkAPI.rejectWithValue('Error fetching data');
	}
});

export const getEnergySetup = createAsyncThunk<
	EnergySetupResponse,
	PropertyIdPayload
>('setup/getEnergySetup', async (payload, thunkAPI) => {
	try {
		const { data: energySetup } = await setupApi.getPropertyEnergySetup(
			payload.propertyId
		);
		if (energySetup) {
			return { energySetup };
		} else {
			return thunkAPI.rejectWithValue('No data found');
		}
	} catch (error) {
		return thunkAPI.rejectWithValue('Error fetching data');
	}
});

export const updateEnergySetup = createAsyncThunk<
	UpdateEnergySetupResponse,
	IdPayload
>('setup/updateEnergySetup', async (payload, thunkAPI) => {
	try {
		const { data } = await setupApi.updatePropertyEnergySetup(
			payload.propertyId,
			payload.column_name,
			payload.column_value
		);

		if (data) {
			return data[0];
		} else {
			return thunkAPI.rejectWithValue('No data found');
		}
	} catch (error) {
		return thunkAPI.rejectWithValue('Error fetching data');
	}
});

const setupSlice = createSlice({
	name: 'setup',
	initialState,
	reducers: {
		addDocument: (state, action: PayloadAction<any[]>) => {
			state.documentList = [...state.documentList, ...action.payload];
		},
		deleteDocument: (state, action: PayloadAction<string>) => {
			state.documentList = state.documentList.filter(
				(doc) => doc?.aws_file_name !== action.payload
			);
		},
		updateEnergyRow: (state, action: PayloadAction<any>) => {
			state.energySetupData = {
				...state.energySetupData,
				...action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDocumentDefinition.pending, (state) => {
				state.documentDefinitionLoading = true;
				state.documentDefinitionError = null;
			})
			.addCase(getDocumentDefinition.fulfilled, (state, action) => {
				state.documentDefinition = action.payload.documentDefinition;
				state.documentDefinitionLoading = false;
				state.documentDefinitionError = null;
			})
			.addCase(getDocumentDefinition.rejected, (state, action) => {
				state.documentDefinitionLoading = false;
				state.documentDefinitionError = action.payload as string;
			})
			.addCase(getDocumentList.pending, (state) => {
				state.documentListLoading = true;
				state.documentListError = null;
				state.documentList = [];
			})
			.addCase(getDocumentList.fulfilled, (state, action) => {
				state.documentList = action.payload.documentList;
				state.documentListLoading = false;
				state.documentListError = null;
			})
			.addCase(getDocumentList.rejected, (state, action) => {
				state.documentListLoading = false;
				state.documentListError = action.payload as string;
			})
			.addCase(getEnergySetup.pending, (state) => {
				state.energySetupDataLoading = true;
				state.energySetupDataError = null;
				state.energySetupData = {};
			})
			.addCase(getEnergySetup.fulfilled, (state, action) => {
				state.energySetupData = action.payload.energySetup;
				state.energySetupDataLoading = false;
				state.energySetupDataError = null;
			})
			.addCase(getEnergySetup.rejected, (state, action) => {
				state.energySetupDataLoading = false;
				state.energySetupDataError = action.payload as string;
			})
			.addCase(updateEnergySetup.pending, (state) => {
				state.energySetupUpdateLoading = true;
				state.energySetupUpdateError = null;
			})
			.addCase(
				updateEnergySetup.fulfilled,
				(
					state,
					{
						payload,
						meta: {
							arg: { column_name, column_value },
						},
					}
				) => {
					state.energySetupData = {
						...state.energySetupData,
						[column_name]: column_value,
					};
					state.energySetupUpdateLoading = false;
					state.energySetupUpdateError = null;
				}
			)
			.addCase(updateEnergySetup.rejected, (state, action) => {
				state.energySetupUpdateLoading = false;
				state.energySetupUpdateError = action.payload as string;
			});
	},
});

export const { addDocument, deleteDocument, updateEnergyRow } =
	setupSlice.actions;

export default setupSlice.reducer;
