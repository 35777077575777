import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	allChartsDefault,
	emptySelection,
} from '../../components/energyDashboard/basData/const';
import * as basApi from '../../api/basApi';

const checkIfPathExists = (state, chartIndex, selectionCardIndex) => {
	if (!state[chartIndex]) {
		state[chartIndex] = { selectedSensors: [emptySelection] };
	}

	if (!state[chartIndex].selectedSensors[selectionCardIndex]) {
		state[chartIndex].selectedSensors[selectionCardIndex] = {};
	}
};

// Get the sensor data for a given sensor
const getSensorData = async ({
	sensor,
	propertyId,
	startDate = '',
	endDate = '',
}) => {
	const params = {
		'start-date': startDate,
		'end-date': endDate,
		sensor: sensor,
	};

	const { data } = await basApi.getBasData(propertyId, params);
	return data || null; // Return data or null if not found
};

// Fetch the sensor data for the list of selected sensors
export const fetchAndUpdateSensors = createAsyncThunk(
	'sensors/fetchAndUpdateSensors',
	async (
		{
			chartIndex,
			selectionCardIndex,
			selectionCardSensorList,
			propertyId,
			startDate,
			endDate,
			isDateUpdated = false,
			allowedUnit = null,
		},
		{ dispatch, getState }
	) => {
		// Get the original sensor list to check if the sensor is already in the state
		const originalSelectionCardSensorInfo =
			getState().bas.data?.[chartIndex]?.selectedSensors?.[selectionCardIndex]
				.sensors;
		const originalSelectionCardSensorList =
			originalSelectionCardSensorInfo?.length
				? originalSelectionCardSensorInfo.map(({ name }) => name)
				: [];

		// Get the sensor data for each sensor in the list
		const sensorDataPromises = selectionCardSensorList.map(async (sensor) => {
			// If the sensor is already in the state, don't fetch it again
			if (!isDateUpdated && originalSelectionCardSensorList.length > 0) {
				const index = originalSelectionCardSensorList.indexOf(sensor);
				if (index !== -1) {
					return originalSelectionCardSensorInfo[index];
				}
			}

			const data = await getSensorData({
				sensor,
				propertyId,
				startDate,
				endDate,
			});
			return { name: sensor, data: data };
		});

		// Wait for all promises to resolve
		const sensorData = await Promise.all(sensorDataPromises);

		// Dispatch the updateSensors action with the gathered data
		dispatch(
			updateSensors({ chartIndex, selectionCardIndex, sensorData, allowedUnit })
		);
	}
);

const basSlice = createSlice({
	name: 'bas',
	initialState: {
		data: allChartsDefault,
		loading: false,
		error: null,
	},
	reducers: {
		addChart: (state, { payload: chartIndex }) => {
			state.data = [
				...state.data,
				{
					chartName: `Chart ${chartIndex + 1}`,
					unit1: null,
					unit2: null,
					selectedSensors: [emptySelection],
				},
			];
		},
		updateSystem: (state, { payload }) => {
			const { chartIndex, selectionCardIndex, value } = payload;

			// Ensure the path exists before updating
			checkIfPathExists(state, chartIndex, selectionCardIndex);

			// Update the value
			state.data[chartIndex].selectedSensors[selectionCardIndex] = {
				system: value,
				devices: [],
				sensors: [],
			};
		},
		updateDevice: (state, { payload }) => {
			const { chartIndex, selectionCardIndex, value } = payload;

			checkIfPathExists(state, chartIndex, selectionCardIndex);

			// Get the current data
			const data = state.data[chartIndex].selectedSensors[selectionCardIndex];

			data.devices = value;
			data.sensors = data.sensors.filter(({ name }) => {
				const sensorName = name.split('__')[0];
				// If the sensor is in the new device list, keep it
				return value.includes(sensorName);
			});
		},
		updateSensors: (state, { payload }) => {
			const { chartIndex, selectionCardIndex, sensorData, allowedUnit } =
				payload;

			// Ensure the path exists before updating
			checkIfPathExists(state, chartIndex, selectionCardIndex);

			// If a new unit is allowed, update the unit
			if (allowedUnit) {
				state.data[chartIndex].unit1 = allowedUnit;
			}

			// Get the current data
			const data = state.data[chartIndex].selectedSensors[selectionCardIndex];

			// Overwrite with the sensors' data from the API
			data.sensors = sensorData;

			// Add an empty selection
			const selectedSensors = state.data[chartIndex].selectedSensors;

			// If all cards are fully completed, add an empty card
			if (
				!selectedSensors.some((selection) => selection.sensors.length === 0)
			) {
				state.data[chartIndex].selectedSensors = [
					...selectedSensors,
					emptySelection,
				];
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAndUpdateSensors.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchAndUpdateSensors.fulfilled, (state, _) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(fetchAndUpdateSensors.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { addChart, updateSystem, updateDevice, updateSensors } =
	basSlice.actions;

export default basSlice.reducer;
