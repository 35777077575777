import { configureStore } from '@reduxjs/toolkit';
import selectedBuilding from './redux/selectedBuilding/SelectedBuildingSlice';
import user from './redux/user/UserSlice';
import auth from './redux/auth/AuthSlice';
import navbarToggle from './redux/navbar/NavbarToggleSlice';
import buildings from './redux/buildings/BuildingsSlice';
import basReducer from './redux/bas/basSlice';
import storage from 'redux-persist/lib/storage';
import c2bem from './redux/c2bem/c2bemSlice';
import opx from './redux/opx/opxSlice';
import setup from './redux/setup/setupSlice';
import weather from './redux/weather/weatherSlice';
import inputSheet from './redux/inputSheet/inputSheetSlice';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const selectedBuildingPersistConfig = {
	key: 'selectedBuilding',
	storage,
};

const authPersistConfig = {
	key: 'auth',
	storage,
};

const buildingsPersistConfig = {
	key: 'buildings',
	storage,
};

const userPersistConfig = {
	key: 'user',
	storage,
};

const basPersistConfig = {
	key: 'bas',
	storage,
};

const c2bemPersistConfig = {
	key: 'c2bem',
	storage,
};

const setupPersistConfig = {
	key: 'setup',
	storage,
};

const weatherPersistConfig = {
	key: 'weather',
	storage,
};

const inputSheetPersistConfig = {
	key: 'inputSheet',
	storage,
};

const opxPersistConfig = {
	key: 'opx',
	storage,
};

export const store = configureStore({
	reducer: {
		user: persistReducer(userPersistConfig, user),
		auth: persistReducer(authPersistConfig, auth),
		selectedBuilding: persistReducer(
			selectedBuildingPersistConfig,
			selectedBuilding
		),
		buildings: persistReducer(buildingsPersistConfig, buildings),
		bas: persistReducer(basPersistConfig, basReducer),
		c2bem: persistReducer(c2bemPersistConfig, c2bem),
		setup: persistReducer(setupPersistConfig, setup),
		weather: persistReducer(weatherPersistConfig, weather),
		inputSheet: persistReducer(inputSheetPersistConfig, inputSheet),
		opx: persistReducer(opxPersistConfig, opx),
		navbarToggle,
	},
	middleware: [thunk],
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
