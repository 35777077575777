import { Divider, Skeleton } from '@mui/material';
import cardStyles from '../../styles/Cards.module.scss';
import fontStyles from '../../styles/Fonts.module.scss';
import { useSelector } from 'react-redux';

export const additionalStylesEmptyHeader = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: '15vh',
};

const CardHeader = ({
	title = '',
	subtext = '',
	rightAlignedContent = null,
	children,
	additionalStyles = null,
}) => {
	// Check if the building switch from the top dropdown is completed
	const { loading } = useSelector((state) => state.selectedBuilding);
	return (
		<div className={cardStyles.cardContainer} style={additionalStyles}>
			{(title || subtext) && (
				<>
					<div className={cardStyles.infoLeftAndRight}>
						<p>
							<span className={fontStyles.cardTitle}>{title}</span>{' '}
							{subtext &&
								(typeof subtext === 'string' ? `| ${subtext}` : subtext)}
						</p>
						{loading && rightAlignedContent ? (
							<Skeleton width={100} height={40} />
						) : (
							rightAlignedContent
						)}
					</div>
					<Divider sx={{ marginBottom: '20px' }} />
				</>
			)}
			{loading ? (
				<div style={{ minHeight: '300px' }}>
					<Skeleton width={160} height={40} />
					<Skeleton width={100} height={40} />
					<Skeleton width={60} height={40} />
				</div>
			) : (
				children
			)}
		</div>
	);
};

export default CardHeader;
