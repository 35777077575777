import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const checkAccountConnection = async (
	property_id: string, // Joulea property id
	energy_star_account_id: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`v2/p/${property_id}/services/energy-star/check-account-connection`,
		{
			params: { energy_star_account_id },
		}
	);
	return response.data;
};

export const acceptConnectionRequest = async (
	property_id: string, // Joulea property id
	data: {}
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`v2/p/${property_id}/services/energy-star/accept-account-request`,
		data
	);
	return response.data;
};

export const checkPropertySharing = async (
	property_id: string, // Joulea property id
	energy_star_property_id: string,
	energy_star_account_id: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`v2/p/${property_id}/services/energy-star/check-property-sharing`,
		{
			params: { energy_star_property_id, energy_star_account_id },
		}
	);
	return response.data;
};

export const acceptPropertyRequest = async (
	property_id: string, // Joulea property id
	data: {}
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`v2/p/${property_id}/services/energy-star/accept-property-request`,
		data
	);
	return response.data;
};

// TODO [SET-1922]: Send email once a data sharing section is complete
export const sendSharingDataEmail = async (
	data: any
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		'/email/send-email-data-sharing',
		data
	);
	return response.data;
};

export const insertEnergyStarData = async (
	property_id: string, // Joulea property id
	energy_star_property_id: string // Energy Star property id
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.post(
		`v2/p/${property_id}/services/energy-star/energy-star-data`,
		{
			energy_star_property_id: energy_star_property_id,
		}
	);
	return response.data;
};
