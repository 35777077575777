import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as setupApi from '../../api/setupApi';
import { get } from 'lodash';

interface SectionNamePayload {
	sectionName: string;
}

interface PropertyIdPayload {
	propertyId: string;
	sectionName: Array<string>;
}

interface PropertyPayload {
	propertyId: string;
}

interface ResponseValuePayload {
	propertyId: string;
	data: any;
}

interface SectionQuestionsResponse {
	sectionQuestions: any;
}

interface ResponsesResponse {
	responses: any;
}

interface InputSheetState {
	sectionQuestions: any[];
	isSectionQuestionsLoading: boolean;
	sectionQuestionsError: string | null;
	responses: any[];
	isResponsesLoading: boolean;
	responsesError: string | null;
	sectionStatus: any;
	isSectionStatusLoading: boolean;
	sectionStatusError: string | null;
}

export const sectionStatusInitialState = {
	'Atypical Setpoints': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Building Envelope': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Building Information': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	Condensing: {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	Cooling: { isStarted: false, isComplete: false, overwriteVisibility: false },
	Distribution: {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Domestic Hot Water': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Energy Management System': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Floor Information': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Garage Info': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'General Lighting Info': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Heat Pumps': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	Heating: { isStarted: false, isComplete: false, overwriteVisibility: false },
	'Internal Heat Gain': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Lighting In Common Areas': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Lighting In Rented Areas': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	Pumps: { isStarted: false, isComplete: false, overwriteVisibility: false },
	'Roof Material': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Solar Heating': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Solar Panel System': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Temperature Setpoint': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	Ventilation: {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Wall Material': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Window Material': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Guided Session #1 Overview': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
	'Guided Session #2 Overview': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: true,
	},
	'Drone Logistics Overview': {
		isStarted: false,
		isComplete: false,
		overwriteVisibility: false,
	},
};

const initialState: InputSheetState = {
	sectionQuestions: [],
	isSectionQuestionsLoading: false,
	sectionQuestionsError: null,
	responses: [],
	isResponsesLoading: false,
	responsesError: null,
	sectionStatus: sectionStatusInitialState,
	isSectionStatusLoading: false,
	sectionStatusError: null,
};

export const getSectionQuestionsThunk = createAsyncThunk<
	SectionQuestionsResponse,
	SectionNamePayload
>('setup/getSectionQuestions', async ({ sectionName }, thunkAPI) => {
	try {
		const { data: sectionQuestions } = await setupApi.getSectionQuestions(
			sectionName
		);
		if (sectionQuestions) {
			return { sectionQuestions };
		} else {
			return thunkAPI.rejectWithValue('No data found');
		}
	} catch (error) {
		return thunkAPI.rejectWithValue('Error fetching data');
	}
});

export const getResponsesThunk = createAsyncThunk<
	ResponsesResponse,
	PropertyIdPayload
>('setup/getResponses', async ({ propertyId, sectionName }, thunkAPI) => {
	try {
		const { data: responses } = await setupApi.getSectionResponses(
			propertyId,
			sectionName
		);
		if (responses) {
			return { responses };
		} else {
			return thunkAPI.rejectWithValue('No data found');
		}
	} catch (error) {
		return thunkAPI.rejectWithValue('Error fetching data');
	}
});

export const updateSectionResponseThunk = createAsyncThunk(
	'setup/updateSectionResponse',
	async ({ propertyId, data }: ResponseValuePayload, thunkAPI) => {
		try {
			const response = await setupApi.updateSectionResponse(propertyId, data);
			if (response) {
				return response;
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const getSectionStatusThunk = createAsyncThunk(
	'setup/getSectionStatus',
	async ({ propertyId }: PropertyPayload, thunkAPI) => {
		try {
			const { data: response } = await setupApi.getSectionStatus(propertyId);
			if (response) {
				if (response.length === 0) {
					return sectionStatusInitialState;
				}
				// Ensure all keys from sectionStatusInitialState are included in the response
				const mergedResponse = { ...sectionStatusInitialState, ...response };
				return mergedResponse;
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

export const updateSectionStatusThunk = createAsyncThunk(
	'setup/updateSectionStatus',
	async ({ propertyId, data }: ResponseValuePayload, thunkAPI) => {
		try {
			const response = await setupApi.updateSectionStatus(propertyId, data);
			if (response) {
				return response;
			} else {
				return thunkAPI.rejectWithValue('No data found');
			}
		} catch (error) {
			return thunkAPI.rejectWithValue('Error fetching data');
		}
	}
);

const inputSheetSlice = createSlice({
	name: 'inputSheet',
	initialState,
	reducers: {
		updateQuestionResponse(state, action) {
			const {
				questionId,
				value,
				inputJsonKeys,
				questionsToClear = [],
			} = action.payload;
			// Clear child questions if needed
			const clearedChildQuestions = questionsToClear.reduce(
				(acc: any, { question_id }: any) => {
					acc[question_id] = {
						response_value: null,
						jsonKey: null,
					};
					return acc;
				},
				{}
			);
			state.responses = {
				...state.responses,
				[questionId]: {
					response_value: value,
					jsonKey: inputJsonKeys,
				},
				...clearedChildQuestions,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSectionQuestionsThunk.pending, (state) => {
			state.isSectionQuestionsLoading = true;
			state.sectionQuestions = [];
			state.sectionQuestionsError = null;
		});
		builder.addCase(getSectionQuestionsThunk.fulfilled, (state, action) => {
			state.isSectionQuestionsLoading = false;
			state.sectionQuestions = action.payload.sectionQuestions;
			state.sectionQuestionsError = null;
		});
		builder.addCase(getSectionQuestionsThunk.rejected, (state, { payload }) => {
			state.isSectionQuestionsLoading = false;
			state.sectionQuestionsError = payload as string;
		});
		builder.addCase(getResponsesThunk.pending, (state) => {
			state.isResponsesLoading = true;
			state.responses = [];
			state.responsesError = null;
		});
		builder.addCase(getResponsesThunk.fulfilled, (state, action) => {
			state.isResponsesLoading = false;
			state.responses = action.payload.responses;
		});
		builder.addCase(getResponsesThunk.rejected, (state, { payload }) => {
			state.isResponsesLoading = false;
			state.responsesError = payload as string;
		});
		builder.addCase(updateSectionResponseThunk.pending, (state) => {
			state.responsesError = null;
		});
		builder.addCase(
			updateSectionResponseThunk.rejected,
			(state, { payload }) => {
				state.responsesError = payload as string;
			}
		);
		builder.addCase(getSectionStatusThunk.pending, (state) => {
			state.isSectionStatusLoading = true;
			state.sectionStatus = sectionStatusInitialState;
			state.sectionStatusError = null;
		});
		builder.addCase(getSectionStatusThunk.fulfilled, (state, action) => {
			state.isSectionStatusLoading = false;
			state.sectionStatus = action.payload;
			state.sectionStatusError = null;
		});
		builder.addCase(getSectionStatusThunk.rejected, (state, { payload }) => {
			state.sectionStatusError = payload as string;
		});
		builder.addCase(updateSectionStatusThunk.pending, (state) => {
			state.sectionStatusError = null;
		});
		builder.addCase(updateSectionStatusThunk.rejected, (state, { payload }) => {
			state.sectionStatusError = payload as string;
		});
		builder.addCase(updateSectionStatusThunk.fulfilled, (state, action) => {
			const { propertyId, data } = action.meta.arg; // Access the payload
			state.sectionStatus = {
				...state.sectionStatus,
				...data, // Merge the updated section status into the state
			};
		});
	},
});

export const { updateQuestionResponse } = inputSheetSlice.actions;

export default inputSheetSlice.reducer;
