import { useEffect, useState } from 'react';
import {
	FormControlLabel,
	Checkbox,
	CircularProgress,
	Typography,
} from '@mui/material';
import '../../DataSharing.scss';
import { InstructionStep } from '../../CommonTemplates';
import { acceptPropertyRequest } from '../../../../../api/energyStarApi';
import { energyStarJsonKeys, energyStarPageKeys } from '../../const';
import { ActionButtonGroup } from '../../CommonTemplates';
import { energyStarApi } from '../../../../../api';
import { useDispatch } from 'react-redux';
import { updateEnergySetup } from '../../../../../redux/setup/setupSlice';

function EnergyStarSharePropertySteps({
	propertyNumber,
	isShared,
	setCurrentPage,
	propertyId,
	id,
}) {
	const dispatch = useDispatch();
	const [hasSharedCheckbox, setHasSharedCheckbox] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const unableToAcceptMessage = `Sorry, we're having trouble accepting your property share request (Property ID: ${propertyNumber}). Please try again or contact us for assistance.`;

	useEffect(() => {
		setHasSharedCheckbox(isShared);
	}, [isShared]);

	const handleOnCheckboxChange = async () => {
		try {
			setLoading(true);
			setErrorMessage('');

			// Check if Joulea has accepted the property sharing request
			const response = await acceptPropertyRequest(propertyId, {
				energy_star_property_id: propertyNumber,
			});

			if (response.data === true) {
				// If the property is shared, insert the data to the database
				energyStarApi.insertEnergyStarData(propertyId, propertyNumber);
				setHasSharedCheckbox(true);
				dispatch(
					updateEnergySetup({
						propertyId,
						column_name: energyStarJsonKeys?.IS_SHARED,
						column_value: true,
					})
				);
				setCurrentPage(energyStarPageKeys?.SUCCESS);
			} else {
				setErrorMessage(unableToAcceptMessage);
			}
			setLoading(false);
		} catch (error) {
			setErrorMessage(unableToAcceptMessage);
			setLoading(false);
		}
	};

	return (
		<div className="InstructionsContainer">
			<p className="TopInstruction">
				Please follow the steps below to share your properties with Joulea on
				Energy Star Portfolio Manager.
			</p>
			<div className="ConnectionSteps">
				<InstructionStep
					stepNumber={1}
					stepDescription={
						<>
							Log on to{' '}
							<a
								href="https://portfoliomanager.energystar.gov/pm/login.html"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'underline', color: '#212121' }}>
								Energy Star Portfolio Manager.
							</a>
						</>
					}
				/>
				<InstructionStep
					stepNumber={2}
					stepDescription={
						<>
							Open the <span className="bold">Sharing tab</span>.
						</>
					}
				/>
				<InstructionStep
					stepNumber={3}
					stepDescription={
						<>
							Click{' '}
							<span className="bold">
								Share with your Utility or Service Provider
							</span>{' '}
							for exchanging data.
						</>
					}
				/>
				<InstructionStep
					stepNumber={4}
					stepDescription={
						<>
							Select <span className="bold">Joulea</span> from the dropdown.
						</>
					}
				/>
				<InstructionStep
					stepNumber={5}
					stepDescription={
						<>
							Click <span className="bold">Select Properties</span> and select
							the property names.
						</>
					}
				/>
				<InstructionStep
					stepNumber={6}
					stepDescription={
						<>
							Select <span className="bold">Bulk Sharing</span> (Simple Option)
							and the sub option 
							<span className="bold">Exchange Data Read Only Access</span> (with
							read only access to all properties and meters)
						</>
					}
				/>
				<InstructionStep
					stepNumber={7}
					stepDescription={
						<>
							Click <span className="bold">Authorize Exchange</span>.
						</>
					}
				/>
				<FormControlLabel
					control={
						loading ? (
							<CircularProgress
								size={20}
								className="checking-connection-spinner"
							/>
						) : (
							<Checkbox
								checked={hasSharedCheckbox}
								onChange={() => {
									!isShared && handleOnCheckboxChange();
								}}
							/>
						)
					}
					label="I have shared the property with Joulea."
				/>
				{errorMessage && <Typography color="error">{errorMessage}</Typography>}
			</div>
			<ActionButtonGroup
				pageToNavigateOnBack={energyStarPageKeys.GET_PROPERTY_NUMBER}
				pageJsonKey={energyStarJsonKeys.energyStarPageNumber}
				setPageNumber={setCurrentPage}
			/>
		</div>
	);
}

export default EnergyStarSharePropertySteps;
