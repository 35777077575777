import axiosInstance from './axiosInstance';

interface Filters {
	[key: string]: any;
}

export const searchBuildings = async (filters: Filters) => {
	const response = await axiosInstance.get('/search/buildings', {
		params: filters,
	});
	return response.data;
};

export const searchFlightPlans = async (filters: Filters) => {
	const response = await axiosInstance.get('/search/flight-plans', {
		params: filters,
	});
	return response.data;
};

export const searchImages = async (filters: Filters) => {
	const response = await axiosInstance.get('/search/images', {
		params: filters,
	});
	return response.data;
};
